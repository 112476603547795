@layer modules, ui, base;
@layer ui {
  .sk-CardContainer_container__PNt2O {
  container-name: --sk-card;
  container-type: inline-size;
}

}
@layer ui {
  .sk-Card_main__0BVRy {
  --sk-Card-orientation: column;
  --sk-CardCover-margin-x: calc(var(--sk-data-display-card-cover-margin-left) + var(--sk-data-display-card-cover-margin-right));
  --sk-CardCover-margin-y: calc(var(--sk-data-display-card-cover-margin-top) + var(--sk-data-display-card-cover-margin-bottom));
  --sk-CardCover-min-height: unset;

  display: flex;
  box-sizing: border-box;
  flex-direction: var(--sk-Card-orientation);
  width: 100%;
  overflow: hidden;
  border-width: var(--sk-data-display-card-size-border-width);
  border-style: solid;
  border-radius: var(--sk-data-display-card-radius);
  font-family: var(--sk-font-families-poppins);
}

/**
 * Override link style inside card.
 */
.sk-Card_main__0BVRy > [href] {
  outline-offset: calc(var(--sk-data-display-card-size-border-width) * -1);
  color: inherit;
  text-decoration: none;
}

/* Fix alignment and margin when Footer is inside Body */
.sk-Card_main__0BVRy .sk-CardBody > .sk-CardFooter {
  align-items: center;
  margin: auto 0 0;
}

/**
 * Orientation: vertical.
 */
.sk-Card_main--vertical__MyYrK {
  min-width: var(--sk-data-display-card-size-min-width-vertical);
}

/**
 * Orientation: horizontal.
 */
.sk-Card_main--horizontal__sDdRD {
  --sk-Card-orientation: row;
  --sk-CardCover-min-height: auto;
  --sk-CardCover-height: unset;

  min-width: var(--sk-data-display-card-size-min-width-horizontal);
}

.sk-Card_main--horizontal__sDdRD .sk-CardBody + .sk-CardFooter {
  flex-basis: fit-content;
  flex-flow: column wrap;
  flex-shrink: 0;
  align-items: flex-end;
  justify-content: space-between;
  margin-left: 0;
}

/**
 * Responsive vertical orientation when container between 240 & 447px
 */

@container --sk-card (min-width: 240px) {
  .sk-Card_main--responsive__lt2R0 {
    min-width: var(--sk-data-display-card-size-min-width-vertical);
  }
}

/**
 * Responsive horizontal orientation when container >= 448px
 */

@container --sk-card (min-width: 448px) {
  .sk-Card_main--responsive__lt2R0 {
    --sk-Card-orientation: row;

    min-width: var(--sk-data-display-card-size-min-width-horizontal);
  }

  .sk-Card_main--responsive__lt2R0 .sk-CardBody + .sk-CardFooter {
    flex-basis: fit-content;
    flex-flow: column wrap;
    flex-shrink: 0;
    align-items: flex-end;
    justify-content: space-between;
    margin-left: 0;
  }
}

/**
 * onBackground style.
 */
.sk-Card_main--light__GD6gW {
  border-color: var(--sk-data-display-card-color-border-default-light);
  background-color: var(--sk-data-display-card-color-background-default-light);
  color: var(--sk-data-display-card-color-default-light);
}

.sk-Card_main--dark__EwWC_ {
  border-color: var(--sk-data-display-card-color-border-default-dark);
  background-color: var(--sk-data-display-card-color-background-default-dark);
  color: var(--sk-data-display-card-color-default-dark);
}

}
@layer ui {
  .sk-CardBody_main__MOweR {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: var(--sk-data-display-card-body-margin-top) var(--sk-data-display-card-body-margin-right) var(--sk-data-display-card-body-margin-bottom) var(--sk-data-display-card-body-margin-left);
  gap: var(--sk-data-display-card-body-gap);
}

}
@layer ui {
  .sk-CardBodyContent_main__5L1zn {
  display: flex;
  flex-direction: column;
  gap: var(--sk-data-display-card-body-content-gap);
  flex-grow: 1;
}

}
@layer ui {
  .sk-CardBodyHeader_main__9_8dW {
  display: flex;
  flex-direction: column;
  gap: var(--sk-data-display-card-body-header-gap);
}

}
@layer ui {
  .sk-Typography_main__I32O9 {
  margin: unset;
  padding: unset;
}

/**
 * Tokens seems wrong for font families and weights but they are not.
 * The naming constraint came from Token Studio in Figma part where tokens are
 * generated. Token Studio creates as many tokens as there are variants.
 * For example, if we have the following configuration:
 * 3 hierarchy variants
 * 3 weights variants
 * 2 font families
 * Token Studio will create: 3 * 3 * 2 = 18 tokens.
 * Instead, we can handle variants separately and have only 3 + 3 + 2 = 8 tokens.
 * In our case, all Text class names use:
 * - the same font family
 * - the same font weight according to weight variant
 *   (regardless of the font used: body1, body2 etc.).
 */

/**
 * Font families
 */

.sk-Typography_title1__kpKKc,
.sk-Typography_title2___x7oz,
.sk-Typography_display1__01oEX,
.sk-Typography_display2__CnKww {
  font-family: var(--sk-typography-title-1-font-family), helvetica, arial, sans-serif;
}

.sk-Typography_body1__rvFYv,
.sk-Typography_body2__W0CNH,
.sk-Typography_title3__c40PS,
.sk-Typography_caption1__sl2pb,
.sk-Typography_caption1Underline__eZKaw {
  font-family: var(--sk-typography-body-1-regular-font-family), helvetica, arial, sans-serif;
}

/**
 * Weights
 */

.sk-Typography_regular__a_y2X {
  font-weight: var(--sk-typography-body-1-regular-font-weight);
}

.sk-Typography_semiBold__OC7cU {
  font-weight: var(--sk-typography-body-1-semibold-font-weight);
}

.sk-Typography_bold__ICdus {
  font-weight: var(--sk-typography-body-1-bold-font-weight);
}

/**
 * @FIXME: title token has been moved (.title1 uses --…-title-2) for mobile handling.
 *   See with @valentinmichel-jt for what needs to be done to make it more maintainable.
 *   N.B. .title4 is not handled yet and, in this case, it will miss the --…-title-5 token.
 *        We'll also have an n+1 missing token for each title variant added.
 */

.sk-Typography_title1__kpKKc {
  font-size: var(--sk-typography-title-2-font-size);
  letter-spacing: 0;
  line-height: var(--sk-typography-title-2-line-height);
  text-decoration: none;
}

.sk-Typography_title2___x7oz {
  font-size: var(--sk-typography-title-3-font-size);
  letter-spacing: 0;
  line-height: var(--sk-typography-title-3-line-height);
  text-decoration: none;
}

.sk-Typography_title3__c40PS {
  font-size: var(--sk-typography-title-4-font-size);
  letter-spacing: 0;
  line-height: var(--sk-typography-title-4-line-height);
  text-decoration: none;
}

.sk-Typography_body1__rvFYv {
  font-size: var(--sk-typography-body-1-regular-font-size);
  letter-spacing: 0;
  line-height: var(--sk-typography-body-1-regular-line-height);
  text-decoration: none;
}

.sk-Typography_body2__W0CNH {
  font-size: var(--sk-typography-body-2-regular-font-size);
  letter-spacing: 0;
  line-height: var(--sk-typography-body-2-regular-line-height);
  text-decoration: none;
}

.sk-Typography_caption1__sl2pb,
.sk-Typography_caption1Underline__eZKaw {
  font-size: var(--sk-typography-caption-1-font-size);
  letter-spacing: 0;
  line-height: var(--sk-typography-caption-1-line-height);
  text-decoration: none;
}

.sk-Typography_caption1Underline__eZKaw {
  text-decoration: underline;
}

.sk-Typography_display1__01oEX {
  font-size: var(--sk-typography-display-1-font-size);
  letter-spacing: 0;
  line-height: var(--sk-typography-display-1-line-height);
  text-decoration: none;
}

.sk-Typography_display2__CnKww {
  font-size: var(--sk-typography-display-2-font-size);
  letter-spacing: 0;
  line-height: var(--sk-typography-display-2-line-height);
  text-decoration: none;
}

@media (min-width: 768px) {
  .sk-Typography_title1__kpKKc {
    font-size: var(--sk-typography-title-1-font-size);
    line-height: var(--sk-typography-title-1-line-height);
  }

  .sk-Typography_title2___x7oz {
    font-family: var(--sk-typography-title-2-font-family), helvetica, arial, sans-serif;
    font-size: var(--sk-typography-title-2-font-size);
    font-weight: var(--sk-typography-title-2-font-weight);
    line-height: var(--sk-typography-title-2-line-height);
  }

  .sk-Typography_title3__c40PS {
    font-size: var(--sk-typography-title-3-font-size);
    line-height: var(--sk-typography-title-3-line-height);
  }
}

}
@layer ui {
  .sk-Link_main__nOUpr {
  --Link-external-icon-caption1-size: var(--sk-size-12);
  --Link-external-icon-body2-size: var(--sk-size-16);
  --Link-external-icon-body1-size: var(--sk-size-20);
  --Link-external-icon-size: var(--Link-external-icon-body2-size);
  --Link-external-icon-data: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiBoZWlnaHQ9IjI0IiB2aWV3Qm94PSIwIDAgMjQgMjQiIHdpZHRoPSIyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Im0xNSA1Yy0uNTUyMyAwLTEtLjQ0NzcyLTEtMXMuNDQ3Ny0xIDEtMWg1Yy41NTIzIDAgMSAuNDQ3NzIgMSAxdjVjMCAuNTUyMjgtLjQ0NzcgMS0xIDFzLTEtLjQ0NzcyLTEtMXYtMi41ODU3OWwtOC4yOTI5IDguMjkyODljLS4zOTA1LjM5MDUtMS4wMjM2OC4zOTA1LTEuNDE0MjEgMC0uMzkwNTItLjM5MDUtLjM5MDUyLTEuMDIzNyAwLTEuNDE0Mmw4LjI5MjkxLTguMjkyOXptLTExLjEyMTMyIDEuODc4NjhjLjU2MjYxLS41NjI2MSAxLjMyNTY3LS44Nzg2OCAyLjEyMTMyLS44Nzg2OGg1Yy41NTIzIDAgMSAuNDQ3NzIgMSAxcy0uNDQ3NyAxLTEgMWgtNWMtLjI2NTIyIDAtLjUxOTU3LjEwNTM2LS43MDcxMS4yOTI4OS0uMTg3NTMuMTg3NTQtLjI5Mjg5LjQ0MTg5LS4yOTI4OS43MDcxMXY5YzAgLjI2NTIuMTA1MzYuNTE5Ni4yOTI4OS43MDcxLjE4NzU0LjE4NzUuNDQxODkuMjkyOS43MDcxMS4yOTI5aDljLjI2NTIgMCAuNTE5Ni0uMTA1NC43MDcxLS4yOTI5cy4yOTI5LS40NDE5LjI5MjktLjcwNzF2LTVjMC0uNTUyMy40NDc3LTEgMS0xczEgLjQ0NzcgMSAxdjVjMCAuNzk1Ny0uMzE2MSAxLjU1ODctLjg3ODcgMi4xMjEzcy0xLjMyNTYuODc4Ny0yLjEyMTMuODc4N2gtOWMtLjc5NTY1IDAtMS41NTg3MS0uMzE2MS0yLjEyMTMyLS44Nzg3cy0uODc4NjgtMS4zMjU3LS44Nzg2OC0yLjEyMTN2LTljMC0uNzk1NjUuMzE2MDctMS41NTg3MS44Nzg2OC0yLjEyMTMyeiIgZmlsbD0iI2ZmZiIgZmlsbC1ydWxlPSJldmVub2RkIi8+PC9zdmc+");

  display: inline;
  /* This property is required when Link is used inside flex parent. */
  width: -moz-fit-content;
  width: fit-content;
  border-radius: var(--sk-navigation-link-radius);
  outline: none;
  font-family: var(--sk-typography-navigation-link-text-typography-m-font-family), helvetica, arial, sans-serif;
  font-size: inherit;
  font-weight: var(--sk-typography-navigation-link-text-typography-m-font-weight);
  line-height: inherit;
  text-decoration: underline;
  cursor: pointer;
}

.sk-Link_main--external__hOR_4::after {
  content: "";
  display: inline-flex;
  width: var(--Link-external-icon-size);
  height: var(--Link-external-icon-size);
  margin-left: var(--sk-space-2);
  /* Adjust svg alignment with text */
  transform: translateY(-0.0625rem); /* -1px */
  /* Use the same color of the link for the external icon. */
  background-color: currentcolor;
  vertical-align: sub;
  /* Required by Sandpack as it uses css as is. */
  -webkit-mask-image: var(--Link-external-icon-data);
  mask-image: var(--Link-external-icon-data);
  -webkit-mask-size: cover;
  mask-size: cover;
}

.sk-Link_main__nOUpr:focus-visible {
  outline-offset: 0;
  outline-style: solid;
  outline-width: var(--sk-navigation-link-size-border-focus);
}

.sk-Link_main--caption1__YoyQD {
  font-size: var(--sk-typography-navigation-link-text-typography-s-font-size);
  font-weight: var(--sk-typography-navigation-link-text-typography-s-font-weight);
  line-height: var(--sk-typography-navigation-link-text-typography-s-line-height);
}

.sk-Link_main--caption1__YoyQD.sk-Link_main--external__hOR_4::after {
  --Link-external-icon-size: var(--Link-external-icon-caption1-size);

  line-height: var(--sk-typography-navigation-link-text-typography-s-line-height);
}

.sk-Link_main--body1__4qy4x {
  font-size: var(--sk-typography-navigation-link-text-typography-l-font-size);
  line-height: var(--sk-typography-navigation-link-text-typography-l-line-height);
}

.sk-Link_main--body1__4qy4x.sk-Link_main--external__hOR_4::after {
  --Link-external-icon-size: var(--Link-external-icon-body1-size);

  line-height: var(--sk-typography-navigation-link-text-typography-l-line-height);
}

.sk-Link_main--body2__5CxGA {
  font-size: var(--sk-typography-navigation-link-text-typography-m-font-size);
  line-height: var(--sk-typography-navigation-link-text-typography-m-line-height);
}

.sk-Link_main--body2__5CxGA.sk-Link_main--external__hOR_4::after {
  --Link-external-icon-size: var(--Link-external-icon-body2-size);

  line-height: var(--sk-typography-navigation-link-text-typography-m-line-height);
}

.sk-Link_main--light__sHPm0 {
  color: var(--sk-navigation-link-color-default-light);
}

.sk-Link_main--light__sHPm0:focus-visible {
  outline-color: var(--sk-navigation-link-color-border-focus-light);
}

.sk-Link_main--light__sHPm0:hover {
  background-color: var(--sk-navigation-link-color-background-hover-light);
  color: var(--sk-navigation-link-color-hover-light);
}

.sk-Link_main--dark__PTzF2 {
  color: var(--sk-navigation-link-color-default-dark);
}

.sk-Link_main--dark__PTzF2:focus-visible {
  outline-color: var(--sk-navigation-link-color-border-focus-dark);
}

.sk-Link_main--dark__PTzF2:hover {
  background-color: var(--sk-navigation-link-color-background-hover-dark);
  color: var(--sk-navigation-link-color-hover-dark);
}

}
@layer ui {
  .sk-CardCover_main__mcHXh {
  --sk-CardCover-ratio: 1;

  position: relative;
  z-index: 0;
  box-sizing: border-box;
  width: calc(100% - var(--sk-data-display-card-cover-margin-right) - var(--sk-data-display-card-cover-margin-left));
  height: auto;
  margin: var(--sk-data-display-card-cover-margin-top) var(--sk-data-display-card-cover-margin-right) var(--sk-data-display-card-cover-margin-bottom) var(--sk-data-display-card-cover-margin-left);
  padding: var(--sk-data-display-card-cover-padding);
  overflow: hidden;
  aspect-ratio: var(--sk-CardCover-ratio);
}

.sk-CardCover_main--fullWidth__ChWFx {
  --sk-data-display-card-cover-radius: 0;

  width: 100%;
  margin: 0;
}

.sk-CardCover_main__mcHXh > *:not(.sk-CardCoverImage) {
  position: relative;
  z-index: 2;
  max-width: 100%;
}

}
@layer ui {
  .sk-Divider_main__KCaPd {
  --Divider-size: calc(var(--sk-size-2) / 2);

  display: inline-flex;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
}

.sk-Divider_main--horizontal__3iUOd {
  flex: 0 1 auto;
  justify-content: center;
  width: 100%;
  height: var(--Divider-size);
}

.sk-Divider_main--vertical__F_EbS {
  flex: 0 0 var(--Divider-size);
  align-self: stretch;
  justify-content: center;
  width: var(--Divider-size);
  min-height: 100%;
}

.sk-Divider_main--light__2ofRn {
  background-color: var(--sk-color-dark-20);
}

.sk-Divider_main--dark__DBhlj {
  background-color: var(--sk-color-light-30);
}

}
@layer ui {
  .sk-CreateOptionItem_main__QR0iA {
  display: -webkit-box;
  z-index: 0;
  align-items: center;
  padding: var(--sk-space-12) var(--sk-space-16);
  overflow: hidden;
  border: none;
  background-color: transparent;
  color: var(--sk-color-black);
  font-family: var(--sk-typography-body-2-regular-font-family);
  font-size: var(--sk-typography-body-2-regular-font-size);
  font-weight: var(--sk-typography-body-2-regular-font-weight);
  line-height: var(--sk-typography-body-2-regular-line-height);
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.sk-CreateOptionItem_main__QR0iA:hover {
  background-color: var(--sk-color-dark-10);
}

.sk-CreateOptionItem_main__QR0iA:focus-visible {
  z-index: 1;
  outline: 3px solid var(--sk-color-purple-400);
}

}
@layer ui {
  .sk-Checkbox_container__iYz32 {
  position: relative;
}

.sk-Checkbox_main__eUdvn {
  display: flex;
  box-sizing: border-box;
  align-items: center;
  gap: var(--sk-space-8);
  padding: var(--sk-space-12);
  transition: background-color 100ms ease-out;
  border-radius: var(--sk-form-checkbox-radius);
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}

.sk-Checkbox_hiddenCheckboxInput__0NUuq {
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  border: 0;
}

.sk-Checkbox_checkbox__y5PLh {
  display: inline-flex;
  box-sizing: border-box;
  flex-shrink: 0;
  align-self: center;
  width: var(--sk-size-20);
  height: var(--sk-size-20);
  margin: var(--sk-space-2) 0;
  border: var(--sk-size-2) solid;
  border-radius: var(--sk-form-checkbox-input-radius);
  place-items: center center;
}

.sk-Checkbox_label__S3CpC {
  font-family: var(--sk-typography-form-checkbox-typography-text-m-font-family);
  font-size: var(--sk-typography-form-checkbox-typography-text-m-font-size);
  font-weight: var(--sk-typography-form-checkbox-typography-text-m-font-weight);
  line-height: var(--sk-typography-form-checkbox-typography-text-m-line-height);
}

.sk-Checkbox_labelWrapper--dropdown-item__TMFtl {
  display: flex;
  align-items: center;
  min-width: 0;
  gap: var(--sk-space-4);
}

.sk-Checkbox_label--dropdown-item__5FX5b {
  display: -webkit-box;
  overflow: hidden;
  color: var(--sk-color-black);
  font-family: var(--sk-typography-body-2-regular-font-family);
  font-size: var(--sk-typography-body-2-regular-font-size);
  font-weight: var(--sk-typography-body-2-regular-font-weight);
  line-height: var(--sk-typography-body-2-regular-line-height);
  text-overflow: ellipsis;
  white-space: normal;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.sk-Checkbox_main--checked__6uyhF .sk-Checkbox_label--dropdown-item__5FX5b {
  font-family: var(--sk-typography-body-2-semibold-font-family);
  font-size: var(--sk-typography-body-2-semibold-font-size);
  font-weight: var(--sk-typography-body-2-semibold-font-weight);
  line-height: var(--sk-typography-body-2-semibold-line-height);
}

.sk-Checkbox_secondaryLabel__jzvUl {
  margin-left: var(--sk-space-8);
  color: var(--sk-color-grey-600);
  font-family: var(--sk-typography-form-checkbox-typography-text-m-font-family);
  font-size: var(--sk-typography-form-checkbox-typography-text-m-font-size);
  font-weight: var(--sk-typography-form-checkbox-typography-text-m-font-weight);
  line-height: var(--sk-typography-form-checkbox-typography-text-m-line-height);
}

.sk-Checkbox_secondaryLabel--dropdown-item__6oeMp {
  overflow: hidden;
  color: var(--sk-color-grey-600);
  font-family: var(--sk-typography-body-2-regular-font-family);
  font-size: var(--sk-typography-body-2-regular-font-size);
  font-weight: var(--sk-typography-body-2-regular-font-weight);
  line-height: var(--sk-typography-body-2-regular-line-height);
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sk-Checkbox_errorMessage___j55S {
  padding: var(--sk-space-2) var(--sk-space-12);
  color: var(--sk-color-error-500);
}

.sk-Checkbox_checkedState__IoFPY {
  flex-shrink: 0;
}

.sk-Checkbox_main--disabled__Tug8s {
  cursor: not-allowed;
}

.sk-Checkbox_main--light__bacP3 {
  color: var(--sk-form-checkbox-color-default-light);
}

.sk-Checkbox_main--dark__rFl_O {
  color: var(--sk-form-checkbox-color-default-dark);
}

.sk-Checkbox_main--light__bacP3:hover:not(.sk-Checkbox_main--disabled__Tug8s, .sk-Checkbox_main--dropdown-item__nNJXx) {
  background-color: var(--sk-form-checkbox-color-background-hover-light);
  color: var(--sk-form-checkbox-color-hover-light);
}

.sk-Checkbox_main--dark__rFl_O:hover:not(.sk-Checkbox_main--disabled__Tug8s, .sk-Checkbox_main--dropdown-item__nNJXx) {
  background-color: var(--sk-form-checkbox-color-background-hover-dark);
  color: var(--sk-form-checkbox-color-hover-dark);
}

input:focus-visible + .sk-Checkbox_main__eUdvn {
  outline: none;
  box-shadow: var(--sk-shadow-focus-ring-light-shadow-3), var(--sk-shadow-focus-ring-light-shadow-2),
    var(--sk-shadow-focus-ring-light-shadow-1);
}

input:focus-visible + .sk-Checkbox_main--light__bacP3 {
  background-color: var(--sk-form-checkbox-color-background-focus-light);
  color: var(--sk-form-checkbox-color-focus-light);
}

input:focus-visible + .sk-Checkbox_main--dark__rFl_O {
  background-color: var(--sk-form-checkbox-color-background-focus-dark);
  color: var(--sk-form-checkbox-color-focus-dark);
}

.sk-Checkbox_main--light__bacP3.sk-Checkbox_main--disabled__Tug8s {
  color: var(--sk-form-checkbox-color-disabled-light);
}

.sk-Checkbox_main--dark__rFl_O.sk-Checkbox_main--disabled__Tug8s {
  color: var(--sk-form-checkbox-color-disabled-dark);
}

.sk-Checkbox_main--light__bacP3.sk-Checkbox_main--checked__6uyhF.sk-Checkbox_main--disabled__Tug8s,
.sk-Checkbox_main--light__bacP3.sk-Checkbox_main--indeterminate___tYpt.sk-Checkbox_main--disabled__Tug8s {
  color: var(--sk-form-checkbox-color-disabled-light);
}

.sk-Checkbox_main--dark__rFl_O.sk-Checkbox_main--checked__6uyhF.sk-Checkbox_main--disabled__Tug8s,
.sk-Checkbox_main--dark__rFl_O.sk-Checkbox_main--indeterminate___tYpt.sk-Checkbox_main--disabled__Tug8s {
  color: var(--sk-form-checkbox-color-disabled-dark);
}

.sk-Checkbox_main--light__bacP3:hover:not(.sk-Checkbox_main--disabled__Tug8s) .sk-Checkbox_checkbox__y5PLh {
  border-color: var(--sk-form-checkbox-input-color-border-hover-light);
  color: var(--sk-form-checkbox-input-color-hover-light);
}

.sk-Checkbox_main--light__bacP3:hover:not(.sk-Checkbox_main--disabled__Tug8s, .sk-Checkbox_main--dropdown-item__nNJXx) + .sk-Checkbox_main--light__bacP3.sk-Checkbox_main--checked__6uyhF .sk-Checkbox_checkbox__y5PLh,
.sk-Checkbox_main--light__bacP3:hover:not(.sk-Checkbox_main--disabled__Tug8s, .sk-Checkbox_main--dropdown-item__nNJXx) + .sk-Checkbox_main--light__bacP3.sk-Checkbox_main--dark__rFl_O.sk-Checkbox_main--indeterminate___tYpt .sk-Checkbox_checkbox__y5PLh {
  background-color: var(--sk-form-checkbox-input-color-background-checked-hover-light);
}

.sk-Checkbox_main--dark__rFl_O:hover:not(.sk-Checkbox_main--disabled__Tug8s) .sk-Checkbox_checkbox__y5PLh {
  border-color: var(--sk-form-checkbox-input-color-border-hover-dark);
  color: var(--sk-form-checkbox-input-color-hover-dark);
}

.sk-Checkbox_main--dark__rFl_O:hover:not(.sk-Checkbox_main--disabled__Tug8s, .sk-Checkbox_main--dropdown-item__nNJXx) + .sk-Checkbox_main--dark__rFl_O.sk-Checkbox_main--checked__6uyhF .sk-Checkbox_checkbox__y5PLh,
.sk-Checkbox_main--dark__rFl_O:hover:not(.sk-Checkbox_main--disabled__Tug8s, .sk-Checkbox_main--dropdown-item__nNJXx) + .sk-Checkbox_main--dark__rFl_O.sk-Checkbox_main--dark__rFl_O.sk-Checkbox_main--indeterminate___tYpt .sk-Checkbox_checkbox__y5PLh {
  background-color: var(--sk-form-checkbox-input-color-background-checked-hover-dark);
}

input:focus-visible + .sk-Checkbox_main--light__bacP3 .sk-Checkbox_checkbox__y5PLh {
  border-color: var(--sk-form-checkbox-input-color-border-focus-light);
  color: var(--sk-form-checkbox-input-color-focus-light);
}

input:focus-visible + .sk-Checkbox_main--light__bacP3.sk-Checkbox_main--checked__6uyhF .sk-Checkbox_checkbox__y5PLh,
input:focus-visible + .sk-Checkbox_main--light__bacP3.sk-Checkbox_main--light__bacP3.sk-Checkbox_main--indeterminate___tYpt .sk-Checkbox_checkbox__y5PLh {
  background-color: var(--sk-form-checkbox-input-color-background-checked-focus-light);
}

input:focus-visible + .sk-Checkbox_main--dark__rFl_O .sk-Checkbox_checkbox__y5PLh {
  border-color: var(--sk-form-checkbox-input-color-border-focus-dark);
  color: var(--sk-form-checkbox-input-color-focus-dark);
}

input:focus-visible + .sk-Checkbox_main--dark__rFl_O.sk-Checkbox_main--checked__6uyhF .sk-Checkbox_checkbox__y5PLh,
input:focus-visible + .sk-Checkbox_main--dark__rFl_O.sk-Checkbox_main--dark__rFl_O.sk-Checkbox_main--indeterminate___tYpt .sk-Checkbox_checkbox__y5PLh {
  background-color: var(--sk-form-checkbox-input-color-background-checked-focus-dark);
}

.sk-Checkbox_main--light__bacP3.sk-Checkbox_main--disabled__Tug8s .sk-Checkbox_checkbox__y5PLh {
  border-color: var(--sk-form-checkbox-input-color-border-disabled-light);
  color: var(--sk-form-checkbox-input-color-disabled-light);
}

.sk-Checkbox_main--light__bacP3.sk-Checkbox_main--checked__6uyhF.sk-Checkbox_main--disabled__Tug8s .sk-Checkbox_checkbox__y5PLh,
.sk-Checkbox_main--light__bacP3.sk-Checkbox_main--indeterminate___tYpt.sk-Checkbox_main--disabled__Tug8s .sk-Checkbox_checkbox__y5PLh {
  background-color: var(--sk-form-checkbox-input-color-background-checked-disabled-light);
}

.sk-Checkbox_main--dark__rFl_O.sk-Checkbox_main--disabled__Tug8s .sk-Checkbox_checkbox__y5PLh {
  border-color: var(--sk-form-checkbox-input-color-border-disabled-dark);
  color: var(--sk-form-checkbox-input-color-disabled-dark);
}

.sk-Checkbox_main--dark__rFl_O.sk-Checkbox_main--checked__6uyhF.sk-Checkbox_main--disabled__Tug8s .sk-Checkbox_checkbox__y5PLh,
.sk-Checkbox_main--dark__rFl_O.sk-Checkbox_main--indeterminate___tYpt.sk-Checkbox_main--disabled__Tug8s .sk-Checkbox_checkbox__y5PLh {
  background-color: var(--sk-form-checkbox-input-color-background-checked-disabled-dark);
}

.sk-Checkbox_main--light__bacP3 .sk-Checkbox_secondaryLabel__jzvUl {
  color: var(--sk-form-checkbox-secondary-label-color-default-light);
}

.sk-Checkbox_main--dark__rFl_O .sk-Checkbox_secondaryLabel__jzvUl {
  color: var(--sk-form-checkbox-secondary-label-color-default-dark);
}

.sk-Checkbox_main--light__bacP3:hover:not(.sk-Checkbox_main--disabled__Tug8s) .sk-Checkbox_secondaryLabel__jzvUl {
  color: var(--sk-form-checkbox-secondary-label-color-hover-light);
}

.sk-Checkbox_main--dark__rFl_O:hover:not(.sk-Checkbox_main--disabled__Tug8s) .sk-Checkbox_secondaryLabel__jzvUl {
  color: var(--sk-form-checkbox-secondary-label-color-hover-dark);
}

input:focus-visible + .sk-Checkbox_main--light__bacP3 .sk-Checkbox_secondaryLabel__jzvUl {
  color: var(--sk-form-checkbox-secondary-label-color-focus-light);
}

input:focus-visible + .sk-Checkbox_main--dark__rFl_O .sk-Checkbox_secondaryLabel__jzvUl {
  color: var(--sk-form-checkbox-secondary-label-color-focus-dark);
}

.sk-Checkbox_main--light__bacP3.sk-Checkbox_main--disabled__Tug8s .sk-Checkbox_secondaryLabel__jzvUl {
  color: var(--sk-form-checkbox-secondary-label-color-disabled-light);
}

.sk-Checkbox_main--dark__rFl_O.sk-Checkbox_main--disabled__Tug8s .sk-Checkbox_secondaryLabel__jzvUl {
  color: var(--sk-form-checkbox-secondary-label-color-disabled-dark);
}

.sk-Checkbox_main--light__bacP3 .sk-Checkbox_checkedState__IoFPY {
  color: var(--sk-form-checkbox-input-color-default-light);
}

.sk-Checkbox_main--dark__rFl_O .sk-Checkbox_checkedState__IoFPY {
  color: var(--sk-form-checkbox-input-color-default-dark);
}

.sk-Checkbox_main--light__bacP3.sk-Checkbox_main--checked__6uyhF .sk-Checkbox_checkbox__y5PLh,
.sk-Checkbox_main--light__bacP3.sk-Checkbox_main--indeterminate___tYpt .sk-Checkbox_checkbox__y5PLh {
  background-color: var(--sk-form-checkbox-input-color-background-checked-default-light);
}

.sk-Checkbox_main--dark__rFl_O.sk-Checkbox_main--checked__6uyhF .sk-Checkbox_checkbox__y5PLh,
.sk-Checkbox_main--dark__rFl_O.sk-Checkbox_main--indeterminate___tYpt .sk-Checkbox_checkbox__y5PLh {
  background-color: var(--sk-form-checkbox-input-color-background-checked-default-dark);
}

}
@layer ui {
  .sk-CardCoverImage_main__sXQ31 {
  position: absolute;
  z-index: 1;
  inset: 0;
  pointer-events: none;
  aspect-ratio: var(--sk-CardCover-ratio);
}

.sk-CardCoverImage_main__sXQ31 > img {
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  height: 100%;
  border-radius: var(--sk-data-display-card-cover-radius);
}

}
@layer ui {
  .sk-IconButton_main__Kzatv {
  --IconButton-title-vertical-padding: 0.35rem;
  --IconButton-title-font-size: 0.8rem;
  --IconButton-top-position: calc(
    calc(var(--IconButton-title-font-size) + 2 * var(--IconButton-title-vertical-padding)) * -1
  );

  display: inline-flex;
  position: relative;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  padding: var(--sk-space-12);
  overflow: visible;
  transition: background-color 100ms ease-out;
  border: none;
  outline: none;
  cursor: pointer;
}

.sk-IconButton_main__Kzatv:disabled {
  cursor: not-allowed;
}

.sk-IconButton_main__Kzatv:active {
  transition: none;
}

.sk-IconButton_main--extraSmall__h0QpA {
  width: calc(var(--sk-size-24));
  height: calc(var(--sk-size-24));
  padding: var(--sk-space-4);
  border-radius: var(--sk-radius-8);
}

.sk-IconButton_main--small__AogZE {
  width: calc(var(--sk-size-40) + var(--sk-size-4));
  height: calc(var(--sk-size-40) + var(--sk-size-4));
  border-radius: var(--sk-space-8);
}

.sk-IconButton_main--medium__5g9RA {
  width: var(--sk-size-48);
  height: var(--sk-size-48);
  border-radius: var(--sk-space-12);
}

.sk-IconButton_main--outlined__IjORq {
  padding: var(--sk-space-8);
  border-width: var(--sk-border-width-2);
  border-style: solid;
}

/**
 * Variant: transparent on light
 */

.sk-IconButton_main--transparent__0WLhg.sk-IconButton_main--light__3E_eM {
  background-color: var(--sk-action-icon-button-color-background-primary-default-light);
  color: var(--sk-action-icon-button-color-primary-default-light);
}

.sk-IconButton_main--transparent__0WLhg.sk-IconButton_main--light__3E_eM:enabled:hover {
  background-color: var(--sk-action-icon-button-color-background-primary-hover-light);
}

.sk-IconButton_main--transparent__0WLhg.sk-IconButton_main--light__3E_eM:enabled:focus-visible {
  background-color: var(--sk-action-icon-button-color-background-primary-focus-light);
  box-shadow: var(--sk-ressource-focus-ring-shadow-light-shadow-3), var(--sk-ressource-focus-ring-shadow-light-shadow-2),
    var(--sk-ressource-focus-ring-shadow-light-shadow-1);
}

.sk-IconButton_main--transparent__0WLhg.sk-IconButton_main--light__3E_eM:enabled:active {
  background-color: var(--sk-action-icon-button-color-background-primary-active-light);
}

.sk-IconButton_main--transparent__0WLhg.sk-IconButton_main--light__3E_eM:disabled {
  background-color: var(--sk-action-icon-button-color-background-primary-disabled-light);
  color: var(--sk-action-icon-button-color-primary-disabled-light);
}

/**
 * Variant: filled on light
 */

.sk-IconButton_main--filled__E40Ge.sk-IconButton_main--light__3E_eM {
  background-color: var(--sk-action-icon-button-color-background-secondary-default-light);
  color: var(--sk-action-icon-button-color-secondary-default-light);
}

.sk-IconButton_main--filled__E40Ge.sk-IconButton_main--light__3E_eM:enabled:hover {
  background-color: var(--sk-action-icon-button-color-background-secondary-hover-light);
}

.sk-IconButton_main--filled__E40Ge.sk-IconButton_main--light__3E_eM:enabled:focus-visible {
  background-color: var(--sk-action-icon-button-color-background-secondary-focus-light);
  box-shadow: var(--sk-ressource-focus-ring-shadow-light-shadow-3), var(--sk-ressource-focus-ring-shadow-light-shadow-2),
    var(--sk-ressource-focus-ring-shadow-light-shadow-1);
}

.sk-IconButton_main--filled__E40Ge.sk-IconButton_main--light__3E_eM:enabled:active {
  background-color: var(--sk-action-icon-button-color-background-secondary-active-light);
}

.sk-IconButton_main--filled__E40Ge.sk-IconButton_main--light__3E_eM:disabled {
  background-color: var(--sk-action-icon-button-color-background-secondary-disabled-light);
  color: var(--sk-action-icon-button-color-secondary-disabled-light);
}

/**
 * Variant: outlined on light
 */

.sk-IconButton_main--outlined__IjORq.sk-IconButton_main--light__3E_eM {
  border-color: var(--sk-action-icon-button-color-border-tertiary-default-light);
  background-color: var(--sk-action-icon-button-color-background-tertiary-default-light);
  color: var(--sk-action-icon-button-color-tertiary-default-light);
}

.sk-IconButton_main--outlined__IjORq.sk-IconButton_main--light__3E_eM:enabled:hover {
  border-color: var(--sk-action-icon-button-color-border-tertiary-hover-light);
  background-color: var(--sk-action-icon-button-color-background-tertiary-hover-light);
}

.sk-IconButton_main--outlined__IjORq.sk-IconButton_main--light__3E_eM:enabled:focus-visible {
  background-color: var(--sk-action-icon-button-color-background-tertiary-focus-light);
  box-shadow: var(--sk-ressource-focus-ring-shadow-light-shadow-3), var(--sk-ressource-focus-ring-shadow-light-shadow-2),
    var(--sk-ressource-focus-ring-shadow-light-shadow-1);
}

.sk-IconButton_main--outlined__IjORq.sk-IconButton_main--light__3E_eM:enabled:active {
  border-color: var(--sk-action-icon-button-color-border-tertiary-active-light);
  background-color: var(--sk-action-icon-button-color-background-tertiary-active-light);
}

.sk-IconButton_main--outlined__IjORq.sk-IconButton_main--light__3E_eM:disabled {
  border-color: var(--sk-action-icon-button-color-border-tertiary-disabled-light);
  color: var(--sk-action-icon-button-color-tertiary-disabled-light);
}

/**
 * Variant: transparent on dark
 */

.sk-IconButton_main--transparent__0WLhg.sk-IconButton_main--dark__DqpiO {
  background-color: var(--sk-action-icon-button-color-background-primary-default-dark);
  color: var(--sk-action-icon-button-color-primary-default-dark);
}

.sk-IconButton_main--transparent__0WLhg.sk-IconButton_main--dark__DqpiO:enabled:hover {
  background-color: var(--sk-action-icon-button-color-background-primary-hover-dark);
}

.sk-IconButton_main--transparent__0WLhg.sk-IconButton_main--dark__DqpiO:enabled:focus-visible {
  background-color: var(--sk-action-icon-button-color-background-primary-focus-dark);
  box-shadow: var(--sk-ressource-focus-ring-shadow-dark-shadow-3), var(--sk-ressource-focus-ring-shadow-dark-shadow-2),
    var(--sk-ressource-focus-ring-shadow-dark-shadow-1);
}

.sk-IconButton_main--transparent__0WLhg.sk-IconButton_main--dark__DqpiO:enabled:active {
  background-color: var(--sk-action-icon-button-color-background-primary-active-dark);
}

.sk-IconButton_main--transparent__0WLhg.sk-IconButton_main--dark__DqpiO:disabled {
  background-color: var(--sk-action-icon-button-color-background-primary-disabled-dark);
  color: var(--sk-action-icon-button-color-primary-disabled-dark);
}

/**
 * Variant: filled on dark
 */

.sk-IconButton_main--filled__E40Ge.sk-IconButton_main--dark__DqpiO {
  background-color: var(--sk-action-icon-button-color-background-secondary-default-dark);
  color: var(--sk-action-icon-button-color-secondary-default-dark);
}

.sk-IconButton_main--filled__E40Ge.sk-IconButton_main--dark__DqpiO:enabled:hover {
  background-color: var(--sk-action-icon-button-color-background-secondary-hover-dark);
}

.sk-IconButton_main--filled__E40Ge.sk-IconButton_main--dark__DqpiO:enabled:focus-visible {
  background-color: var(--sk-action-icon-button-color-background-secondary-focus-dark);
  box-shadow: var(--sk-ressource-focus-ring-shadow-dark-shadow-3), var(--sk-ressource-focus-ring-shadow-dark-shadow-2),
    var(--sk-ressource-focus-ring-shadow-dark-shadow-1);
}

.sk-IconButton_main--filled__E40Ge.sk-IconButton_main--dark__DqpiO:enabled:active {
  background-color: var(--sk-action-icon-button-color-background-secondary-active-dark);
}

.sk-IconButton_main--filled__E40Ge.sk-IconButton_main--dark__DqpiO:disabled {
  background-color: var(--sk-action-icon-button-color-background-secondary-disabled-dark);
  color: var(--sk-action-icon-button-color-secondary-disabled-dark);
}

/**
 * Variant: outlined on dark
 */

.sk-IconButton_main--outlined__IjORq.sk-IconButton_main--dark__DqpiO {
  border-color: var(--sk-action-icon-button-color-border-tertiary-default-dark);
  background-color: var(--sk-action-icon-button-color-background-tertiary-default-dark);
  color: var(--sk-action-icon-button-color-tertiary-default-dark);
}

.sk-IconButton_main--outlined__IjORq.sk-IconButton_main--dark__DqpiO:enabled:hover {
  border-color: var(--sk-action-icon-button-color-border-tertiary-hover-dark);
  background-color: var(--sk-action-icon-button-color-background-tertiary-hover-dark);
}

.sk-IconButton_main--outlined__IjORq.sk-IconButton_main--dark__DqpiO:enabled:focus-visible {
  background-color: var(--sk-action-icon-button-color-background-tertiary-focus-dark);
  box-shadow: var(--sk-ressource-focus-ring-shadow-dark-shadow-3), var(--sk-ressource-focus-ring-shadow-dark-shadow-2),
    var(--sk-ressource-focus-ring-shadow-dark-shadow-1);
}

.sk-IconButton_main--outlined__IjORq.sk-IconButton_main--dark__DqpiO:enabled:active {
  border-color: var(--sk-action-icon-button-color-border-tertiary-active-dark);
  background-color: var(--sk-action-icon-button-color-background-tertiary-active-dark);
}

.sk-IconButton_main--outlined__IjORq.sk-IconButton_main--dark__DqpiO:disabled {
  border-color: var(--sk-action-icon-button-color-border-tertiary-disabled-dark);
  color: var(--sk-action-icon-button-color-tertiary-disabled-dark);
}

/**
 * Hack to display title attribute in mobile
 */

@media only screen and (max-width: 600px) {
  .sk-IconButton_main__Kzatv[title]:active::before {
    content: attr(title);
    display: inline-block;
    position: absolute;
    top: calc(var(--IconButton-top-position) - 0.4rem);
    left: 50%;
    width: -moz-fit-content;
    width: fit-content;
    padding: var(--IconButton-title-vertical-padding) 0.55rem;
    transform: translateX(-50%);
    border-radius: 2px;
    outline: solid 2px rgba(56, 56, 57, 0.8);
    background-color: rgb(56, 56, 57);
    color: var(--sk-color-white);
    font-size: var(--IconButton-title-font-size);
    white-space: nowrap;
  }
}

}
@layer ui {
  .sk-OptionItem_main__PKJv5 {
  display: flex;
  position: relative;
  z-index: 0;
  align-items: center;
  padding: var(--sk-space-12) var(--sk-space-16);
  font-weight: var(--sk-typography-body-2-regular-font-weight);
  cursor: pointer;
  gap: var(--sk-space-4);
}

.sk-OptionItem_main--with-checkbox__wz8E3 {
  display: block;
  padding: 0;
}

.sk-OptionItem_main__PKJv5:hover {
  background-color: var(--sk-color-dark-10);
}

.sk-OptionItem_main__PKJv5:focus-visible {
  z-index: 1;
  outline: 3px solid var(--sk-color-purple-400);
}

.sk-OptionItem_label__AUbyF {
  display: -webkit-box;
  overflow: hidden;
  color: var(--sk-color-black);
  font-family: var(--sk-typography-body-2-regular-font-family);
  font-size: var(--sk-typography-body-2-regular-font-size);
  font-weight: var(--sk-typography-body-2-regular-font-weight);
  line-height: var(--sk-typography-body-2-regular-line-height);
  text-overflow: ellipsis;
  white-space: normal;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.sk-OptionItem_label--highlighted__1iaUZ {
  font-weight: var(--sk-typography-body-2-bold-font-weight);
}

.sk-OptionItem_main--selected__HcIDa .sk-OptionItem_label__AUbyF {
  font-family: var(--sk-typography-body-2-semibold-font-family);
  font-size: var(--sk-typography-body-2-semibold-font-size);
  font-weight: var(--sk-typography-body-2-semibold-font-weight);
  line-height: var(--sk-typography-body-2-semibold-line-height);
}

.sk-OptionItem_secondary-label__TdzFd {
  overflow: hidden;
  color: var(--sk-color-grey-600);
  font-family: var(--sk-typography-body-2-regular-font-family);
  font-size: var(--sk-typography-body-2-regular-font-size);
  font-weight: var(--sk-typography-body-2-regular-font-weight);
  line-height: var(--sk-typography-body-2-regular-line-height);
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sk-OptionItem_icon__4t5fx {
  flex-shrink: 0;
  margin-left: auto;
}

.sk-OptionItem_imageContainer__Nd5Gl {
  display: flex;
  width: var(--sk-space-20);
  height: var(--sk-space-20);
  margin-right: var(--sk-space-4);
}

.sk-OptionItem_imageContainer__Nd5Gl > * {
  border-radius: var(--sk-radius-4);
}

}
@layer ui {
  .sk-CardFooter_main__j47mA {
  display: flex;
  gap: var(--sk-data-display-card-footer-gap);
  margin: var(--sk-data-display-card-footer-margin-top)  var(--sk-data-display-card-footer-margin-right)  var(--sk-data-display-card-footer-margin-bottom)  var(--sk-data-display-card-footer-margin-left);
}

}
@layer ui {
  .sk-Dropdown_main__fxl1w {
  position: absolute;
  z-index: 1;
  box-sizing: border-box;
  width: 100%;
  margin-top: 8px;
  border-radius: var(--sk-form-dropdown-menu-radius);
  box-shadow: var(--sk-form-dropdown-menu-shadow);
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}

.sk-Dropdown_main__option__Y81Aq,
.sk-Dropdown_main__create-option__TUWXS {
  border-radius: var(--sk-form-dropdown-menu-item-radius);
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}

.sk-Dropdown_main__options-list-wrapper__rnVAh {
  padding: var(--sk-space-4);
}

.sk-Dropdown_main__empty__3qFlY {
  padding: var(--sk-space-24) var(--sk-space-12);
  color: var(--sk-color-grey-700);
  font-family: var(--sk-typography-caption-1-font-family);
  font-size: var(--sk-typography-caption-1-font-size);
  font-weight: var(--sk-typography-caption-1-font-weight);
  line-height: var(--sk-typography-caption-1-line-height);
  text-align: center;
}

.sk-Dropdown_main__options-list__EX1L0 {
  max-height: var(--sk-size-320);
  margin: 0;
  padding: 3px;
  overflow-y: auto;
  list-style: none;
  scrollbar-width: thin;
  scrollbar-color: var(--sk-color-grey-100) var(--sk-color-white);
}

.sk-Dropdown_main__option-section__HOObB {
  display: flex;
  flex-direction: column;
}

.sk-Dropdown_main__option-section-label__meXqj {
  display: -webkit-box;
  margin: var(--sk-space-12) var(--sk-space-8);
  overflow: hidden;
  color: var(--sk-color-grey-700);
  font-family: var(--sk-typography-caption-1-font-family);
  font-size: var(--sk-typography-caption-1-font-size);
  font-weight: var(--sk-typography-caption-1-font-weight);
  line-height: var(--sk-typography-caption-1-line-height);
  text-overflow: ellipsis;
  white-space: normal;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.sk-Dropdown_main__create-option__TUWXS {
  width: 100%;
}

.sk-Dropdown_main__divider__s7cM7 {
  display: flex;
  width: auto;
  margin: var(--sk-space-12);
}

.sk-Dropdown_main--light__qU_zB {
  background-color: var(--sk-form-dropdown-menu-color-background-light);
  color: var(--sk-form-dropdown-menu-color-light);
}

.sk-Dropdown_main--light__qU_zB .sk-Dropdown_main__option__Y81Aq {
  color: var(--sk-form-dropdown-menu-item-color-default-light);
}

.sk-Dropdown_main--light__qU_zB .sk-Dropdown_main__option__Y81Aq:hover {
  background-color: var(--sk-form-dropdown-menu-item-color-background-hover-light);
  color: var(--sk-form-dropdown-menu-item-color-hover-light);
}

.sk-Dropdown_main--light__qU_zB .sk-Dropdown_main__option__Y81Aq:focus-visible {
  background-color: var(--sk-form-dropdown-menu-item-color-background-focus-light);
  color: var(--sk-form-dropdown-menu-item-color-focus-light);
}

.sk-Dropdown_main--dark__Wllk8 {
  background-color: var(--sk-form-dropdown-menu-color-background-dark);
  color: var(--sk-form-dropdown-menu-color-dark);
}

.sk-Dropdown_main--dark__Wllk8 .sk-Dropdown_main__option__Y81Aq {
  color: var(--sk-form-dropdown-menu-item-color-default-dark);
}

.sk-Dropdown_main--dark__Wllk8 .sk-Dropdown_main__option__Y81Aq:hover {
  background-color: var(--sk-form-dropdown-menu-item-color-background-hover-dark);
  color: var(--sk-form-dropdown-menu-item-color-hover-dark);
}

.sk-Dropdown_main--dark__Wllk8 .sk-Dropdown_main__option__Y81Aq:focus-visible {
  background-color: var(--sk-form-dropdown-menu-item-color-background-focus-dark);
  color: var(--sk-form-dropdown-menu-item-color-focus-dark);
}

}
@layer ui {
  .sk-SearchableSelect_main--read-only__WXjhR .sk-SearchableSelect_trigger__tmRSC {
  cursor: not-allowed;
}

.sk-SearchableSelect_dropdownContainer__D52vX {
  position: relative;
}

.sk-SearchableSelect_input__QRU9_ {
  width: 100%;
  border: none;
  background-color: transparent;
  font-family: var(--sk-typography-body-2-regular-font-family);
  font-size: var(--sk-typography-body-2-regular-font-size);
  font-weight: var(--sk-typography-body-2-regular-font-weight);
  line-height: var(--sk-typography-body-2-regular-line-height);
  text-overflow: ellipsis;
}

.sk-SearchableSelect_input__QRU9_:-webkit-autofill {
  -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
  -webkit-transition-delay: 9999s;
}

.sk-SearchableSelect_input__QRU9_:-webkit-autofill,
.sk-SearchableSelect_input__QRU9_:-webkit-autofill:hover,
.sk-SearchableSelect_input__QRU9_:-webkit-autofill:focus,
.sk-SearchableSelect_input__QRU9_:-webkit-autofill:active,
.sk-SearchableSelect_input__QRU9_:autofill {
  -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
  -webkit-transition-delay: 9999s;
}

.sk-SearchableSelect_main--read-only__WXjhR .sk-SearchableSelect_input__QRU9_ {
  cursor: not-allowed;
}

.sk-SearchableSelect_input__QRU9_:focus {
  outline: none;
}

.sk-SearchableSelect_input__QRU9_::-moz-placeholder {
  font-family: var(--sk-typography-body-2-regular-font-family);
  font-size: var(--sk-typography-body-2-regular-font-size);
  font-weight: var(--sk-typography-body-2-regular-font-weight);
  line-height: var(--sk-typography-body-2-regular-line-height);
}

.sk-SearchableSelect_input__QRU9_::placeholder {
  font-family: var(--sk-typography-body-2-regular-font-family);
  font-size: var(--sk-typography-body-2-regular-font-size);
  font-weight: var(--sk-typography-body-2-regular-font-weight);
  line-height: var(--sk-typography-body-2-regular-line-height);
}

.sk-SearchableSelect_trigger__tmRSC {
  display: flex;
  position: relative;
  box-sizing: border-box;
  justify-content: space-between;
  width: 100%;
  height: var(--sk-size-48);
  padding: var(--sk-space-16);
  border-width: var(--sk-form-select-field-size-border-width);
  border-style: solid;
  border-radius: var(--sk-form-searchable-select-field-radius);
  outline: unset;
  cursor: text;
  place-items: center;
  gap: var(--sk-space-8);
}

.sk-SearchableSelect_trigger__tmRSC .Placeholder {
  flex-grow: 1;
  padding-right: var(--sk-space-4);
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}

.sk-SearchableSelect_trigger__tmRSC .SelectedOptionLabel,
.sk-SearchableSelect_trigger__tmRSC .Placeholder {
  overflow: hidden;
  font-family: var(--sk-typography-body-2-regular-font-family);
  font-size: var(--sk-typography-body-2-regular-font-size);
  font-weight: var(--sk-typography-body-2-regular-font-weight);
  line-height: var(--sk-typography-body-2-regular-line-height);
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sk-SearchableSelect_rightIcon__PleOP {
  flex-grow: 0;
  flex-shrink: 0;
}

.sk-SearchableSelect_icon__tmdvd {
  flex-shrink: 0;
}

.sk-SearchableSelect_optionImageContainer__nZLOG {
  width: var(--sk-size-20);
  height: var(--sk-size-20);
}

.sk-SearchableSelect_optionImageContainer__nZLOG > * {
  border-radius: var(--sk-radius-4);
}

/* ON LIGHT BACKGROUND */

.sk-SearchableSelect_main--light__zgITV,
.sk-SearchableSelect_main--light__zgITV .sk-SearchableSelect_input__QRU9_ {
  color: var(--sk-form-searchable-select-color-light);
}

.sk-SearchableSelect_main--light__zgITV .sk-SearchableSelect_subLabel__xvHVh {
  color: var(--sk-form-searchable-select-secondary-label-color-light);
}

.sk-SearchableSelect_main--light__zgITV .sk-SearchableSelect_message__N073R {
  color: var(--sk-form-searchable-select-message-color-default-light);
}

.sk-SearchableSelect_main--light__zgITV .sk-SearchableSelect_message--error__movCz {
  color: var(--sk-form-searchable-select-message-color-error-light);
}

.sk-SearchableSelect_main--light__zgITV .sk-SearchableSelect_trigger__tmRSC {
  border-color: var(--sk-form-searchable-select-field-color-border-default-light);
  background-color: var(--sk-form-searchable-select-field-color-background-default-light);
  color: var(--sk-form-searchable-select-field-color-default-light);
}

.sk-SearchableSelect_main--light__zgITV .sk-SearchableSelect_input__QRU9_::-moz-placeholder {
  color: var(--sk-form-searchable-select-field-placeholder-color-light);
}

.sk-SearchableSelect_main--light__zgITV .sk-SearchableSelect_input__QRU9_::placeholder {
  color: var(--sk-form-searchable-select-field-placeholder-color-light);
}

.sk-SearchableSelect_main--light__zgITV.sk-SearchableSelect_main--read-only__WXjhR .sk-SearchableSelect_trigger__tmRSC {
  border-color: var(--sk-form-searchable-select-field-color-border-read-only-light);
  background-color: var(--sk-form-searchable-select-field-color-background-read-only-light);
  color: var(--sk-form-searchable-select-field-color-read-only-light);
}

.sk-SearchableSelect_main--light__zgITV .sk-SearchableSelect_icon__tmdvd,
.sk-SearchableSelect_main--light__zgITV .sk-SearchableSelect_rightIcon__PleOP {
  color: var(--sk-form-searchable-select-field-expand-icon-color-default-light);
}

.sk-SearchableSelect_main--light__zgITV.sk-SearchableSelect_main--read-only__WXjhR .sk-SearchableSelect_icon__tmdvd,
.sk-SearchableSelect_main--light__zgITV.sk-SearchableSelect_main--read-only__WXjhR .sk-SearchableSelect_rightIcon__PleOP {
  color: var(--sk-form-searchable-select-field-expand-icon-color-read-only-light);
}

.sk-SearchableSelect_main--light__zgITV.sk-SearchableSelect_main--error__8iME5 .sk-SearchableSelect_trigger__tmRSC {
  border-color: var(--sk-form-searchable-select-field-color-border-error-default-light);
}

.sk-SearchableSelect_main--light__zgITV.sk-SearchableSelect_main--error__8iME5 .sk-SearchableSelect_trigger__tmRSC:has(.sk-SearchableSelect_input__QRU9_:focus-visible) {
  border-color: var(--sk-form-searchable-select-field-color-border-error-focus-light);
}

.sk-SearchableSelect_main--light__zgITV.sk-SearchableSelect_main--error__8iME5 .sk-SearchableSelect_trigger__tmRSC:hover:not(:focus-visible) {
  border-color: var(--sk-form-searchable-select-field-color-border-error-hover-light);
}

.sk-SearchableSelect_main--light__zgITV.sk-SearchableSelect_main--error__8iME5 .sk-SearchableSelect_trigger__tmRSC:active {
  border-color: var(--sk-form-searchable-select-field-color-border-error-active-light);
}

.sk-SearchableSelect_main--light__zgITV.sk-SearchableSelect_main--read-only__WXjhR .sk-SearchableSelect_trigger__tmRSC:has(.sk-SearchableSelect_input__QRU9_:focus-visible) {
  border-color: var(--sk-form-searchable-select-field-color-border-read-only-focus-light);
  background-color: var(--sk-form-searchable-select-field-color-background-read-only-focus-light);
}

.sk-SearchableSelect_main--light__zgITV:not(.sk-SearchableSelect_main--read-only__WXjhR, .sk-SearchableSelect_main--error__8iME5) .sk-SearchableSelect_trigger__tmRSC:hover {
  border-color: var(--sk-form-searchable-select-field-color-border-hover-light);
  background-color: var(--sk-form-searchable-select-field-color-background-hover-light);
}

.sk-SearchableSelect_main--light__zgITV:not(.sk-SearchableSelect_main--read-only__WXjhR, .sk-SearchableSelect_main--error__8iME5) .sk-SearchableSelect_trigger__tmRSC:active {
  border-color: var(--sk-form-searchable-select-field-color-border-active-light);
  background-color: var(--sk-form-searchable-select-field-color-background-active-light);
}

.sk-SearchableSelect_main--light__zgITV:not(.sk-SearchableSelect_main--read-only__WXjhR, .sk-SearchableSelect_main--error__8iME5) .sk-SearchableSelect_trigger__tmRSC:has(.sk-SearchableSelect_input__QRU9_:focus-visible) {
  border-color: var(--sk-form-searchable-select-field-color-border-focus-light);
  background-color: var(--sk-form-searchable-select-field-color-background-focus-light);
}

.sk-SearchableSelect_main--light__zgITV .sk-SearchableSelect_clearIcon__be5gE {
  color: var(--sk-form-searchable-select-field-clear-icon-color-light);
}

/* ON DARK BACKGROUND */

.sk-SearchableSelect_main--dark__Un2LI,
.sk-SearchableSelect_main--dark__Un2LI .sk-SearchableSelect_input__QRU9_ {
  color: var(--sk-form-searchable-select-color-dark);
}

.sk-SearchableSelect_main--dark__Un2LI .sk-SearchableSelect_subLabel__xvHVh {
  color: var(--sk-form-searchable-select-secondary-label-color-dark);
}

.sk-SearchableSelect_main--dark__Un2LI .sk-SearchableSelect_message__N073R {
  color: var(--sk-form-searchable-select-message-color-default-dark);
}

.sk-SearchableSelect_main--dark__Un2LI .sk-SearchableSelect_message--error__movCz {
  color: var(--sk-form-searchable-select-message-color-error-dark);
}

.sk-SearchableSelect_main--dark__Un2LI .sk-SearchableSelect_trigger__tmRSC {
  border-color: var(--sk-form-searchable-select-field-color-border-default-dark);
  background-color: var(--sk-form-searchable-select-field-color-background-default-dark);
  color: var(--sk-form-searchable-select-field-color-default-dark);
}

.sk-SearchableSelect_main--dark__Un2LI .sk-SearchableSelect_input__QRU9_::-moz-placeholder {
  color: var(--sk-form-searchable-select-field-placeholder-color-dark);
}

.sk-SearchableSelect_main--dark__Un2LI .sk-SearchableSelect_input__QRU9_::placeholder {
  color: var(--sk-form-searchable-select-field-placeholder-color-dark);
}

.sk-SearchableSelect_main--dark__Un2LI.sk-SearchableSelect_main--read-only__WXjhR .sk-SearchableSelect_trigger__tmRSC {
  border-color: var(--sk-form-searchable-select-field-color-border-read-only-dark);
  background-color: var(--sk-form-searchable-select-field-color-background-read-only-dark);
  color: var(--sk-form-searchable-select-field-color-read-only-dark);
}

.sk-SearchableSelect_main--dark__Un2LI .sk-SearchableSelect_icon__tmdvd,
.sk-SearchableSelect_main--dark__Un2LI .sk-SearchableSelect_rightIcon__PleOP {
  color: var(--sk-form-searchable-select-field-expand-icon-color-default-dark);
}

.sk-SearchableSelect_main--dark__Un2LI.sk-SearchableSelect_main--read-only__WXjhR .sk-SearchableSelect_icon__tmdvd,
.sk-SearchableSelect_main--dark__Un2LI.sk-SearchableSelect_main--read-only__WXjhR .sk-SearchableSelect_rightIcon__PleOP {
  color: var(--sk-form-searchable-select-field-expand-icon-color-read-only-dark);
}

.sk-SearchableSelect_main--dark__Un2LI.sk-SearchableSelect_main--error__8iME5 .sk-SearchableSelect_trigger__tmRSC {
  border-color: var(--sk-form-searchable-select-field-color-border-error-default-dark);
}

.sk-SearchableSelect_main--dark__Un2LI.sk-SearchableSelect_main--error__8iME5 .sk-SearchableSelect_trigger__tmRSC:has(.sk-SearchableSelect_input__QRU9_:focus-visible) {
  border-color: var(--sk-form-searchable-select-field-color-border-error-focus-dark);
}

.sk-SearchableSelect_main--dark__Un2LI.sk-SearchableSelect_main--error__8iME5 .sk-SearchableSelect_trigger__tmRSC:hover:not(:focus-visible) {
  border-color: var(--sk-form-searchable-select-field-color-border-error-hover-dark);
}

.sk-SearchableSelect_main--dark__Un2LI.sk-SearchableSelect_main--error__8iME5 .sk-SearchableSelect_trigger__tmRSC:active {
  border-color: var(--sk-form-searchable-select-field-color-border-error-active-dark);
}

.sk-SearchableSelect_main--dark__Un2LI.sk-SearchableSelect_main--read-only__WXjhR .sk-SearchableSelect_trigger__tmRSC:has(.sk-SearchableSelect_input__QRU9_:focus-visible) {
  border-color: var(--sk-form-searchable-select-field-color-border-read-only-focus-dark);
  background-color: var(--sk-form-searchable-select-field-color-background-read-only-focus-dark);
}

.sk-SearchableSelect_main--dark__Un2LI:not(.sk-SearchableSelect_main--read-only__WXjhR, .sk-SearchableSelect_main--error__8iME5) .sk-SearchableSelect_trigger__tmRSC:hover {
  border-color: var(--sk-form-searchable-select-field-color-border-hover-dark);
  background-color: var(--sk-form-searchable-select-field-color-background-hover-dark);
}

.sk-SearchableSelect_main--dark__Un2LI:not(.sk-SearchableSelect_main--read-only__WXjhR, .sk-SearchableSelect_main--error__8iME5) .sk-SearchableSelect_trigger__tmRSC:active {
  border-color: var(--sk-form-searchable-select-field-color-border-active-dark);
  background-color: var(--sk-form-searchable-select-field-color-background-active-dark);
}

.sk-SearchableSelect_main--dark__Un2LI:not(.sk-SearchableSelect_main--read-only__WXjhR, .sk-SearchableSelect_main--error__8iME5) .sk-SearchableSelect_trigger__tmRSC:has(.sk-SearchableSelect_input__QRU9_:focus-visible) {
  border-color: var(--sk-form-searchable-select-field-color-border-focus-dark);
  background-color: var(--sk-form-searchable-select-field-color-background-focus-dark);
}

.sk-SearchableSelect_main--dark__Un2LI .sk-SearchableSelect_clearIcon__be5gE {
  color: var(--sk-form-searchable-select-field-clear-icon-color-dark);
}

}
@layer ui {
  .sk-Button_main__8xbMi {
  --sk-Button-y-padding: var(--sk-space-12);
  --sk-Button-x-small-padding: var(--sk-space-12);
  --sk-Button-x-padding: var(--sk-space-16);
  --sk-Button-text-spacing: var(--sk-space-4);
  --sk-Button-icon-gap: var(--sk-space-4);

  display: inline-flex;
  position: relative;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  padding-top: var(--sk-Button-y-padding);
  padding-bottom: var(--sk-Button-y-padding);
  transition: background-color 100ms ease-out;
  border: none;
  outline: none;
  font-family: var(--sk-typography-action-button-text-typography-m-font-family);
  text-decoration: none;
  cursor: pointer;
  gap: calc(var(--sk-Button-text-spacing) + var(--sk-Button-icon-gap));
}

.sk-Button_icon__MfDio {
  flex-shrink: 0;
}

.sk-Button_main--loading__Bvp4c {
  color: transparent;
  cursor: progress;
}

.sk-Button_main--loading__Bvp4c .sk-Button_loadingIcon__xSPkR {
  color: initial;
}

.sk-Button_loadingIcon__xSPkR {
  position: absolute;
  inset: 0;
  margin: auto;
  animation: sk-Button_spin__0T9Rc 1.5s linear infinite;
}

@keyframes sk-Button_spin__0T9Rc {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.sk-Button_main--disabled__3bTdp {
  cursor: not-allowed;
}

/* Sizes */
.sk-Button_main--small__I4ZXK {
  padding-right: calc(var(--sk-Button-x-small-padding) + var(--sk-Button-text-spacing));
  padding-left: calc(var(--sk-Button-x-small-padding) + var(--sk-Button-text-spacing));
  border-radius: var(--sk-action-button-radius-s);
  font-size: var(--sk-typography-action-button-text-typography-s-font-size);
  font-weight: var(--sk-typography-action-button-text-typography-s-font-weight);
  line-height: var(--sk-typography-action-button-text-typography-s-line-height);
}

.sk-Button_main--medium__vyUkg {
  border-radius: var(--sk-action-button-radius-m);
  font-size: var(--sk-typography-action-button-text-typography-m-font-size);
  font-weight: var(--sk-typography-action-button-text-typography-m-font-weight);
  line-height: var(--sk-typography-action-button-text-typography-m-line-height);
}

.sk-Button_main--large__g_Uug {
  border-radius: var(--sk-action-button-radius-l);
  font-size: var(--sk-typography-action-button-text-typography-l-font-size);
  font-weight: var(--sk-typography-action-button-text-typography-l-font-weight);
  line-height: var(--sk-typography-action-button-text-typography-l-line-height);
}

/* Spacing */
.sk-Button_main--medium__vyUkg,
.sk-Button_main--large__g_Uug {
  padding-right: calc(var(--sk-Button-x-padding) + var(--sk-Button-text-spacing));
  padding-left: calc(var(--sk-Button-x-padding) + var(--sk-Button-text-spacing));
}

.sk-Button_main--small__I4ZXK.sk-Button_main--withIcon__U76B5 {
  padding-right: calc(var(--sk-Button-x-small-padding) + var(--sk-Button-text-spacing));
  padding-left: var(--sk-Button-x-small-padding);
}

.sk-Button_main--medium__vyUkg.sk-Button_main--withIcon__U76B5,
.sk-Button_main--large__g_Uug.sk-Button_main--withIcon__U76B5 {
  padding-right: calc(var(--sk-Button-x-padding) + var(--sk-Button-text-spacing));
  padding-left: var(--sk-Button-x-padding);
}

/* Variants & Background */
/* On background light */
.sk-Button_main--light__Bkfce.sk-Button_main--primary__YNSKR:not(.sk-Button_main--loading__Bvp4c) {
  background-color: var(--sk-action-button-color-background-primary-default-light);
  color: var(--sk-action-button-color-primary-default-light);
}

.sk-Button_main--light__Bkfce.sk-Button_main--primary__YNSKR:not(.sk-Button_main--disabled__3bTdp, .sk-Button_main--loading__Bvp4c):hover {
  background-color: var(--sk-action-button-color-background-primary-hover-light);
  color: var(--sk-action-button-color-primary-hover-light);
}

.sk-Button_main--light__Bkfce.sk-Button_main--primary__YNSKR:not(.sk-Button_main--disabled__3bTdp, .sk-Button_main--loading__Bvp4c):focus-visible {
  background-color: var(--sk-action-button-color-background-primary-focus-light);
  box-shadow: var(--sk-ressource-focus-ring-shadow-light-shadow-3), var(--sk-ressource-focus-ring-shadow-light-shadow-2),
    var(--sk-ressource-focus-ring-shadow-light-shadow-1);
  color: var(--sk-action-button-color-primary-focus-light);
}

.sk-Button_main--light__Bkfce.sk-Button_main--primary__YNSKR:not(.sk-Button_main--disabled__3bTdp, .sk-Button_main--loading__Bvp4c):active {
  background-color: var(--sk-action-button-color-background-primary-active-light);
  color: var(--sk-action-button-color-primary-active-light);
}

.sk-Button_main--light__Bkfce.sk-Button_main--primary__YNSKR.sk-Button_main--loading__Bvp4c {
  background-color: var(--sk-action-button-color-background-primary-active-light);
}

.sk-Button_main--light__Bkfce.sk-Button_main--primary__YNSKR.sk-Button_main--disabled__3bTdp {
  background-color: var(--sk-action-color-background-primary-disabled-light);
  color: var(--sk-action-button-color-primary-disabled-light);
}

.sk-Button_main--light__Bkfce.sk-Button_main--secondary__7_9DM {
  outline: 2px solid var(--sk-action-button-color-border-secondary-default-light);
  outline-offset: -2px;
  background-color: transparent;
}

.sk-Button_main--light__Bkfce.sk-Button_main--secondary__7_9DM:not(.sk-Button_main--loading__Bvp4c) {
  color: var(--sk-action-button-color-secondary-default-light);
}

.sk-Button_main--light__Bkfce.sk-Button_main--secondary__7_9DM:not(.sk-Button_main--disabled__3bTdp, .sk-Button_main--loading__Bvp4c):hover {
  background-color: var(--sk-action-button-color-background-secondary-hover-light);
  color: var(--sk-action-button-color-secondary-hover-light);
}

.sk-Button_main--light__Bkfce.sk-Button_main--secondary__7_9DM:not(.sk-Button_main--disabled__3bTdp, .sk-Button_main--loading__Bvp4c):focus-visible {
  background-color: var(--sk-action-button-color-background-secondary-focus-light);
  box-shadow: var(--sk-ressource-focus-ring-shadow-light-shadow-3), var(--sk-ressource-focus-ring-shadow-light-shadow-2),
    var(--sk-ressource-focus-ring-shadow-light-shadow-1);
  color: var(--sk-action-button-color-secondary-focus-light);
}

.sk-Button_main--light__Bkfce.sk-Button_main--secondary__7_9DM:not(.sk-Button_main--disabled__3bTdp, .sk-Button_main--loading__Bvp4c):active {
  background-color: var(--sk-action-button-color-background-secondary-active-light);
  color: var(--sk-action-button-color-secondary-active-light);
}

.sk-Button_main--light__Bkfce.sk-Button_main--secondary__7_9DM.sk-Button_main--loading__Bvp4c {
  background-color: var(--sk-action-button-color-background-secondary-active-light);
}

.sk-Button_main--light__Bkfce.sk-Button_main--secondary__7_9DM.sk-Button_main--disabled__3bTdp {
  outline: 2px solid var(--sk-action-button-color-border-secondary-disabled-light);
  outline-offset: -2px;
  color: var(--sk-action-button-color-secondary-disabled-light);
}

.sk-Button_main--light__Bkfce.sk-Button_main--tertiary__pCNHc:not(.sk-Button_main--loading__Bvp4c) {
  background-color: transparent;
  color: var(--sk-action-button-color-tertiary-default-light);
}

.sk-Button_main--light__Bkfce.sk-Button_main--tertiary__pCNHc:not(.sk-Button_main--disabled__3bTdp, .sk-Button_main--loading__Bvp4c):hover {
  background-color: var(--sk-action-button-color-background-tertiary-hover-light);
  color: var(--sk-action-button-color-tertiary-hover-light);
}

.sk-Button_main--light__Bkfce.sk-Button_main--tertiary__pCNHc:not(.sk-Button_main--disabled__3bTdp, .sk-Button_main--loading__Bvp4c):focus-visible {
  background-color: var(--sk-action-button-color-background-tertiary-focus-light);
  box-shadow: var(--sk-ressource-focus-ring-shadow-light-shadow-3), var(--sk-ressource-focus-ring-shadow-light-shadow-2),
    var(--sk-ressource-focus-ring-shadow-light-shadow-1);
  color: var(--sk-action-button-color-tertiary-focus-light);
}

.sk-Button_main--light__Bkfce.sk-Button_main--tertiary__pCNHc:not(.sk-Button_main--disabled__3bTdp, .sk-Button_main--loading__Bvp4c):active {
  background-color: var(--sk-action-button-color-background-tertiary-active-light);
  color: var(--sk-action-button-color-tertiary-active-light);
}

.sk-Button_main--light__Bkfce.sk-Button_main--tertiary__pCNHc.sk-Button_main--loading__Bvp4c {
  background-color: var(--sk-action-button-color-background-tertiary-active-light);
}

.sk-Button_main--light__Bkfce.sk-Button_main--tertiary__pCNHc.sk-Button_main--disabled__3bTdp {
  color: var(--sk-action-button-color-tertiary-disabled-light);
}

.sk-Button_main--light__Bkfce.sk-Button_main--highlight__paOrt:not(.sk-Button_main--loading__Bvp4c) {
  background-color: var(--sk-action-button-color-background-highlight-default);
  color: var(--sk-action-button-color-highlight-default);
}

.sk-Button_main--light__Bkfce.sk-Button_main--highlight__paOrt:not(.sk-Button_main--disabled__3bTdp, .sk-Button_main--loading__Bvp4c):hover {
  background-color: var(--sk-action-button-color-background-highlight-hover);
  color: var(--sk-action-button-color-highlight-hover);
}

.sk-Button_main--light__Bkfce.sk-Button_main--highlight__paOrt:not(.sk-Button_main--disabled__3bTdp, .sk-Button_main--loading__Bvp4c):focus-visible {
  background-color: var(--sk-action-button-color-background-highlight-focus);
  box-shadow: var(--sk-ressource-focus-ring-shadow-light-shadow-3), var(--sk-ressource-focus-ring-shadow-light-shadow-2),
    var(--sk-ressource-focus-ring-shadow-light-shadow-1);
  color: var(--sk-action-button-color-highlight-focus);
}

.sk-Button_main--light__Bkfce.sk-Button_main--highlight__paOrt:not(.sk-Button_main--disabled__3bTdp, .sk-Button_main--loading__Bvp4c):active {
  background-color: var(--sk-action-button-color-background-highlight-active);
  color: var(--sk-action-button-color-highlight-active);
}

.sk-Button_main--light__Bkfce.sk-Button_main--highlight__paOrt.sk-Button_main--loading__Bvp4c {
  background-color: var(--sk-action-button-color-background-highlight-active);
}

.sk-Button_main--light__Bkfce.sk-Button_main--highlight__paOrt.sk-Button_main--disabled__3bTdp {
  background-color: var(--sk-action-button-color-background-highlight-disabled);
  color: var(--sk-action-button-color-highlight-disabled);
}

/* On background dark */
.sk-Button_main--dark__reu4z.sk-Button_main--primary__YNSKR {
  background-color: var(--sk-action-button-color-background-primary-default-dark);
}

.sk-Button_main--dark__reu4z.sk-Button_main--primary__YNSKR:not(.sk-Button_main--loading__Bvp4c) {
  color: var(--sk-action-button-color-primary-default-dark);
}

.sk-Button_main--dark__reu4z.sk-Button_main--primary__YNSKR:not(.sk-Button_main--disabled__3bTdp, .sk-Button_main--loading__Bvp4c):hover {
  background-color: var(--sk-action-button-color-background-primary-hover-dark);
  color: var(--sk-action-button-color-primary-hover-dark);
}

.sk-Button_main--dark__reu4z.sk-Button_main--primary__YNSKR:not(.sk-Button_main--disabled__3bTdp, .sk-Button_main--loading__Bvp4c):focus-visible {
  background-color: var(--sk-action-button-color-background-primary-focus-dark);
  box-shadow: var(--sk-ressource-focus-ring-shadow-dark-shadow-3), var(--sk-ressource-focus-ring-shadow-dark-shadow-2),
    var(--sk-ressource-focus-ring-shadow-dark-shadow-1);
  color: var(--sk-action-button-color-primary-focus-dark);
}

.sk-Button_main--dark__reu4z.sk-Button_main--primary__YNSKR:not(.sk-Button_main--disabled__3bTdp, .sk-Button_main--loading__Bvp4c):active {
  background-color: var(--sk-action-button-color-background-primary-active-dark);
  color: var(--sk-action-button-color-primary-active-dark);
}

.sk-Button_main--dark__reu4z.sk-Button_main--primary__YNSKR.sk-Button_main--loading__Bvp4c {
  background-color: var(--sk-action-button-color-background-primary-active-dark);
}

.sk-Button_main--dark__reu4z.sk-Button_main--primary__YNSKR.sk-Button_main--disabled__3bTdp {
  background-color: var(--sk-action-color-background-primary-disabled-dark);
  color: var(--sk-action-button-color-primary-disabled-dark);
}

.sk-Button_main--dark__reu4z.sk-Button_main--secondary__7_9DM {
  outline: 2px solid var(--sk-action-button-color-border-secondary-default-dark);
  outline-offset: -2px;
  background-color: transparent;
}

.sk-Button_main--dark__reu4z.sk-Button_main--secondary__7_9DM:not(.sk-Button_main--loading__Bvp4c) {
  color: var(--sk-action-button-color-secondary-default-dark);
}

.sk-Button_main--dark__reu4z.sk-Button_main--secondary__7_9DM:not(.sk-Button_main--disabled__3bTdp, .sk-Button_main--loading__Bvp4c):hover {
  background-color: var(--sk-action-button-color-background-secondary-hover-dark);
  color: var(--sk-action-button-color-secondary-hover-dark);
}

.sk-Button_main--dark__reu4z.sk-Button_main--secondary__7_9DM:not(.sk-Button_main--disabled__3bTdp, .sk-Button_main--loading__Bvp4c):focus-visible {
  background-color: var(--sk-action-button-color-background-secondary-focus-dark);
  box-shadow: var(--sk-ressource-focus-ring-shadow-dark-shadow-3), var(--sk-ressource-focus-ring-shadow-dark-shadow-2),
    var(--sk-ressource-focus-ring-shadow-dark-shadow-1);
  color: var(--sk-action-button-color-secondary-focus-dark);
}

.sk-Button_main--dark__reu4z.sk-Button_main--secondary__7_9DM:not(.sk-Button_main--disabled__3bTdp, .sk-Button_main--loading__Bvp4c):active {
  background-color: var(--sk-action-button-color-background-secondary-active-dark);
  color: var(--sk-action-button-color-secondary-active-dark);
}

.sk-Button_main--dark__reu4z.sk-Button_main--secondary__7_9DM.sk-Button_main--loading__Bvp4c {
  background-color: var(--sk-action-button-color-background-secondary-active-dark);
}

.sk-Button_main--dark__reu4z.sk-Button_main--secondary__7_9DM.sk-Button_main--disabled__3bTdp {
  outline: 2px solid var(--sk-action-button-color-border-secondary-disabled-dark);
  outline-offset: -2px;
  color: var(--sk-action-button-color-secondary-disabled-dark);
}

.sk-Button_main--dark__reu4z.sk-Button_main--tertiary__pCNHc:not(.sk-Button_main--loading__Bvp4c) {
  background-color: transparent;
  color: var(--sk-action-button-color-tertiary-default-dark);
}

.sk-Button_main--dark__reu4z.sk-Button_main--tertiary__pCNHc:not(.sk-Button_main--disabled__3bTdp, .sk-Button_main--loading__Bvp4c):hover {
  background-color: var(--sk-action-button-color-background-tertiary-hover-dark);
  color: var(--sk-action-button-color-tertiary-hover-dark);
}

.sk-Button_main--dark__reu4z.sk-Button_main--tertiary__pCNHc:not(.sk-Button_main--disabled__3bTdp, .sk-Button_main--loading__Bvp4c):focus-visible {
  background-color: var(--sk-action-button-color-background-tertiary-focus-dark);
  box-shadow: var(--sk-ressource-focus-ring-shadow-dark-shadow-3), var(--sk-ressource-focus-ring-shadow-dark-shadow-2),
    var(--sk-ressource-focus-ring-shadow-dark-shadow-1);
  color: var(--sk-action-button-color-tertiary-focus-dark);
}

.sk-Button_main--dark__reu4z.sk-Button_main--tertiary__pCNHc:not(.sk-Button_main--disabled__3bTdp, .sk-Button_main--loading__Bvp4c):active {
  background-color: var(--sk-action-button-color-background-tertiary-active-dark);
  color: var(--sk-action-button-color-tertiary-active-dark);
}

.sk-Button_main--dark__reu4z.sk-Button_main--tertiary__pCNHc.sk-Button_main--loading__Bvp4c {
  background-color: var(--sk-action-button-color-background-tertiary-active-dark);
}

.sk-Button_main--dark__reu4z.sk-Button_main--tertiary__pCNHc.sk-Button_main--disabled__3bTdp {
  color: var(--sk-action-button-color-tertiary-disabled-dark);
}

.sk-Button_main--dark__reu4z.sk-Button_main--highlight__paOrt:not(.sk-Button_main--loading__Bvp4c) {
  background-color: var(--sk-action-button-color-background-highlight-default);
  color: var(--sk-action-button-color-highlight-default);
}

.sk-Button_main--dark__reu4z.sk-Button_main--highlight__paOrt:not(.sk-Button_main--disabled__3bTdp, .sk-Button_main--loading__Bvp4c):hover {
  background-color: var(--sk-action-button-color-background-highlight-hover);
  color: var(--sk-action-button-color-highlight-hover);
}

.sk-Button_main--dark__reu4z.sk-Button_main--highlight__paOrt:not(.sk-Button_main--disabled__3bTdp, .sk-Button_main--loading__Bvp4c):focus-visible {
  background-color: var(--sk-action-button-color-background-highlight-focus);
  box-shadow: var(--sk-ressource-focus-ring-shadow-dark-shadow-3), var(--sk-ressource-focus-ring-shadow-dark-shadow-2),
    var(--sk-ressource-focus-ring-shadow-dark-shadow-1);
  color: var(--sk-action-button-color-highlight-focus);
}

.sk-Button_main--dark__reu4z.sk-Button_main--highlight__paOrt:not(.sk-Button_main--disabled__3bTdp, .sk-Button_main--loading__Bvp4c):active {
  outline: none;
  background-color: var(--sk-action-button-color-background-highlight-active);
  box-shadow: none;
  color: var(--sk-action-button-color-highlight-active);
}

.sk-Button_main--dark__reu4z.sk-Button_main--highlight__paOrt.sk-Button_main--loading__Bvp4c {
  background-color: var(--sk-action-button-color-background-highlight-active);
}

.sk-Button_main--dark__reu4z.sk-Button_main--highlight__paOrt.sk-Button_main--disabled__3bTdp {
  background-color: var(--sk-action-button-color-background-highlight-disabled);
  color: var(--sk-action-button-color-highlight-disabled);
}

}
@layer ui {
  .sk-TextInput_main__aVRQj input {
  flex-grow: 1;
  min-width: 0;
  padding-right: var(--sk-space-4);
  border: unset;
  outline: unset;
  background-color: unset;
  font-family: var(--sk-typography-body-2-regular-font-family);
  font-size: var(--sk-typography-body-2-regular-font-size);
  font-weight: var(--sk-typography-body-2-regular-font-weight);
  line-height: var(--sk-typography-body-2-regular-line-height);
  text-overflow: ellipsis;
}

.sk-TextInput_main__aVRQj .sk-TextInput_fieldContainer__rxJV_ {
  display: flex;
  box-sizing: border-box;
  height: var(--sk-size-48);
  padding: var(--sk-space-16);
  border-width: var(--sk-form-text-input-field-size-border-width);
  border-style: solid;
  border-radius: var(--sk-form-text-input-field-radius);
  place-items: center;
}

.sk-TextInput_main__aVRQj .sk-TextInput_fieldContainer__rxJV_ .sk-TextInput_clearIcon__kPYFd,
.sk-TextInput_main__aVRQj .sk-TextInput_fieldContainer__rxJV_ .sk-TextInput_passwordIcon__ZcocM {
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: var(--sk-space-4);
  border-radius: var(--sk-radius-8);
}

.sk-TextInput_main__aVRQj .sk-TextInput_fieldContainer__rxJV_ .sk-TextInput_clearIcon--hidden__qtn94 {
  visibility: hidden;
}

.sk-TextInput_main__aVRQj .sk-TextInput_fieldContainer__rxJV_ .sk-TextInput_icon__9U3Wx {
  padding-right: var(--sk-space-8);
}

.sk-TextInput_main__aVRQj input:-webkit-autofill {
  -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
  -webkit-transition-delay: 9999s;
}

.sk-TextInput_main__aVRQj input:-webkit-autofill,
.sk-TextInput_main__aVRQj input:-webkit-autofill:hover,
.sk-TextInput_main__aVRQj input:-webkit-autofill:focus,
.sk-TextInput_main__aVRQj input:-webkit-autofill:active,
.sk-TextInput_main__aVRQj input:autofill {
  -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
  -webkit-transition-delay: 9999s;
}

/* ON LIGHT BACKGROUND */

.sk-TextInput_main--light__Xg6d9 * {
  color: var(--sk-form-text-input-color-light);
}

.sk-TextInput_main--light__Xg6d9 .sk-TextInput_subLabel__GnDMu {
  color: var(--sk-form-text-input-secondary-label-color-light);
}

.sk-TextInput_main--light__Xg6d9 .sk-TextInput_message__z1slJ {
  color: var(--sk-form-text-input-message-color-default-light);
}

.sk-TextInput_main--light__Xg6d9 .sk-TextInput_message--error__a49_j {
  color: var(--sk-form-text-input-message-color-error-light);
}

.sk-TextInput_main--light__Xg6d9 input {
  color: var(--sk-form-text-input-field-color-default-light);
}

.sk-TextInput_main--light__Xg6d9 input::-moz-placeholder {
  color: var(--sk-form-text-input-field-placeholder-color-light);
}

.sk-TextInput_main--light__Xg6d9 input::placeholder {
  color: var(--sk-form-text-input-field-placeholder-color-light);
}

.sk-TextInput_main--light__Xg6d9.sk-TextInput_main--read-only__cGz6a input {
  color: var(--sk-form-text-input-field-color-read-only-light);
}

.sk-TextInput_main--light__Xg6d9 .sk-TextInput_icon__9U3Wx {
  color: var(--sk-form-text-input-field-icon-color-default-light);
}

.sk-TextInput_main--light__Xg6d9.sk-TextInput_main--read-only__cGz6a .sk-TextInput_icon__9U3Wx {
  color: var(--sk-form-text-input-field-icon-color-read-only-light);
}

.sk-TextInput_main--light__Xg6d9 .sk-TextInput_fieldContainer__rxJV_ {
  border-color: var(--sk-form-text-input-field-color-border-default-light);
  background-color: var(--sk-form-text-input-field-color-background-default-light);
}

.sk-TextInput_main--light__Xg6d9.sk-TextInput_main--error__S4Awx .sk-TextInput_fieldContainer__rxJV_ {
  border-color: var(--sk-form-text-input-field-color-border-error-default-light);
}

.sk-TextInput_main--light__Xg6d9.sk-TextInput_main--read-only__cGz6a .sk-TextInput_fieldContainer__rxJV_ {
  border-color: var(--sk-form-text-input-field-color-border-read-only-light);
  background-color: var(--sk-form-text-input-field-color-background-read-only-light);
}

.sk-TextInput_main--light__Xg6d9.sk-TextInput_main--error__S4Awx .sk-TextInput_fieldContainer__rxJV_:focus-within {
  border-color: var(--sk-form-text-input-field-color-border-error-focus-light);
}

.sk-TextInput_main--light__Xg6d9.sk-TextInput_main--error__S4Awx .sk-TextInput_fieldContainer__rxJV_:hover {
  border-color: var(--sk-form-text-input-field-color-border-error-hover-light);
}

.sk-TextInput_main--light__Xg6d9.sk-TextInput_main--error__S4Awx .sk-TextInput_fieldContainer__rxJV_:has(input:active) {
  border-color: var(--sk-form-text-input-field-color-border-error-active-light);
}

.sk-TextInput_main--light__Xg6d9.sk-TextInput_main--read-only__cGz6a .sk-TextInput_fieldContainer__rxJV_:focus-within {
  border-color: var(--sk-form-text-input-field-color-border-read-only-focus-light);
  background-color: var(--sk-form-text-input-field-color-background-read-only-focus-light);
}

.sk-TextInput_main--light__Xg6d9:not(.sk-TextInput_main--read-only__cGz6a, .sk-TextInput_main--error__S4Awx) .sk-TextInput_fieldContainer__rxJV_:hover {
  border-color: var(--sk-form-text-input-field-color-border-hover-light);
  background-color: var(--sk-form-text-input-field-color-background-hover-light);
}

.sk-TextInput_main--light__Xg6d9:not(.sk-TextInput_main--read-only__cGz6a, .sk-TextInput_main--error__S4Awx) .sk-TextInput_fieldContainer__rxJV_:has(input:active) {
  border-color: var(--sk-form-text-input-field-color-border-active-light);
  background-color: var(--sk-form-text-input-field-color-background-active-light);
}

.sk-TextInput_main--light__Xg6d9:not(.sk-TextInput_main--read-only__cGz6a, .sk-TextInput_main--error__S4Awx) .sk-TextInput_fieldContainer__rxJV_:has(input:focus) {
  border-color: var(--sk-form-text-input-field-color-border-focus-light);
  background-color: var(--sk-form-text-input-field-color-background-focus-light);
}

/* ON DARK BACKGROUND */

.sk-TextInput_main--dark__4_6wF * {
  color: var(--sk-form-text-input-color-dark);
}

.sk-TextInput_main--dark__4_6wF .sk-TextInput_subLabel__GnDMu {
  color: var(--sk-form-text-input-secondary-label-color-dark);
}

.sk-TextInput_main--dark__4_6wF .sk-TextInput_message__z1slJ {
  color: var(--sk-form-text-input-message-color-default-dark);
}

.sk-TextInput_main--dark__4_6wF .sk-TextInput_message--error__a49_j {
  color: var(--sk-form-text-input-message-color-error-dark);
}

.sk-TextInput_main--dark__4_6wF input {
  color: var(--sk-form-text-input-field-color-default-dark);
}

.sk-TextInput_main--dark__4_6wF input::-moz-placeholder {
  color: var(--sk-form-text-input-field-placeholder-color-dark);
}

.sk-TextInput_main--dark__4_6wF input::placeholder {
  color: var(--sk-form-text-input-field-placeholder-color-dark);
}

.sk-TextInput_main--dark__4_6wF.sk-TextInput_main--read-only__cGz6a input {
  color: var(--sk-form-text-input-field-color-read-only-dark);
}

.sk-TextInput_main--dark__4_6wF .sk-TextInput_icon__9U3Wx {
  color: var(--sk-form-text-input-field-icon-color-default-dark);
}

.sk-TextInput_main--dark__4_6wF.sk-TextInput_main--read-only__cGz6a .sk-TextInput_icon__9U3Wx {
  color: var(--sk-form-text-input-field-icon-color-read-only-dark);
}

.sk-TextInput_main--dark__4_6wF .sk-TextInput_fieldContainer__rxJV_ {
  border-color: var(--sk-form-text-input-field-color-border-default-dark);
  background-color: var(--sk-form-text-input-field-color-background-default-dark);
}

.sk-TextInput_main--dark__4_6wF.sk-TextInput_main--error__S4Awx .sk-TextInput_fieldContainer__rxJV_ {
  border-color: var(--sk-form-text-input-field-color-border-error-default-dark);
}

.sk-TextInput_main--dark__4_6wF.sk-TextInput_main--error__S4Awx .sk-TextInput_fieldContainer__rxJV_:focus-within {
  border-color: var(--sk-form-text-input-field-color-border-error-focus-dark);
}

.sk-TextInput_main--dark__4_6wF.sk-TextInput_main--error__S4Awx .sk-TextInput_fieldContainer__rxJV_:hover {
  border-color: var(--sk-form-text-input-field-color-border-error-hover-dark);
}

.sk-TextInput_main--dark__4_6wF.sk-TextInput_main--error__S4Awx .sk-TextInput_fieldContainer__rxJV_:has(input:active) {
  border-color: var(--sk-form-text-input-field-color-border-error-active-dark);
}

.sk-TextInput_main--dark__4_6wF.sk-TextInput_main--read-only__cGz6a .sk-TextInput_fieldContainer__rxJV_ {
  border-color: var(--sk-form-text-input-field-color-border-read-only-dark);
  background-color: var(--sk-form-text-input-field-color-background-read-only-dark);
}

.sk-TextInput_main--dark__4_6wF.sk-TextInput_main--read-only__cGz6a .sk-TextInput_fieldContainer__rxJV_:focus-within {
  border-color: var(--sk-form-text-input-field-color-border-read-only-focus-dark);
  background-color: var(--sk-form-text-input-field-color-background-read-only-focus-dark);
}

.sk-TextInput_main--dark__4_6wF:not(.sk-TextInput_main--read-only__cGz6a, .sk-TextInput_main--error__S4Awx) .sk-TextInput_fieldContainer__rxJV_:hover {
  border-color: var(--sk-form-text-input-field-color-border-hover-dark);
  background-color: var(--sk-form-text-input-field-color-background-hover-dark);
}

.sk-TextInput_main--dark__4_6wF:not(.sk-TextInput_main--read-only__cGz6a, .sk-TextInput_main--error__S4Awx) .sk-TextInput_fieldContainer__rxJV_:has(input:active) {
  border-color: var(--sk-form-text-input-field-color-border-active-dark);
  background-color: var(--sk-form-text-input-field-color-background-active-dark);
}

.sk-TextInput_main--dark__4_6wF:not(.sk-TextInput_main--read-only__cGz6a, .sk-TextInput_main--error__S4Awx, :has(input:active)) .sk-TextInput_fieldContainer__rxJV_:has(input:focus) {
  border-color: var(--sk-form-text-input-field-color-border-focus-dark);
  background-color: var(--sk-form-text-input-field-color-background-focus-dark);
}

}
@layer ui {
  .sk-Avatar_main__vOWfc {
  --sk-Avatar-border-radius: var(--sk-data-display-avatar-radius-m);
  --sk-Avatar-size: var(--sk-data-display-avatar-size-m);

  display: inline-flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: var(--sk-Avatar-size);
  height: var(--sk-Avatar-size);
  overflow: hidden;
  text-align: center;
}

.sk-Avatar_sideImage__4ebVa {
  --sk-Avatar-side-image-size: var(--sk-data-display-avatar-side-image-size);
  --sk-Avatar-side-image-border-radius: var(--sk-data-display-avatar-side-image-radius);

  position: absolute;
  z-index: 1000;
  right: 0;
  bottom: 0;
  width: var(--sk-Avatar-side-image-size);
  height: var(--sk-Avatar-side-image-size);
  border-radius: var(--sk-Avatar-side-image-border-radius);
  background-color: var(--sk-color-white);
}

.sk-Avatar_sideImage__4ebVa > * {
  border-radius: var(--sk-Avatar-side-image-border-radius);
}

.sk-Avatar_main--fallback-icon__6hMe5 {
  background-color: var(--sk-color-grey-800);
  color: var(--sk-color-white);
}

.sk-Avatar_main--squared__HPuTd > *:not(.sk-Avatar_sideImage__4ebVa) {
  border-radius: var(--sk-Avatar-border-radius);
}

.sk-Avatar_main--rounded__dp6R1 > *:not(.sk-Avatar_sideImage__4ebVa),
.sk-Avatar_main--rounded__dp6R1.sk-Avatar_main--fallback-icon__6hMe5 {
  border-radius: var(--sk-radius-rounded);
}

.sk-Avatar_main--size-m__4JSoT {
  --sk-Avatar-size: var(--sk-data-display-avatar-size-m);
  --sk-Avatar-border-radius: var(--sk-data-display-avatar-radius-m);
}

.sk-Avatar_main--size-l__6vabu {
  --sk-Avatar-size: var(--sk-data-display-avatar-size-l);
  --sk-Avatar-border-radius: var(--sk-data-display-avatar-radius-l);
}

.sk-Avatar_main--size-2xl__qhAOn {
  --sk-Avatar-size: var(--sk-data-display-avatar-size-2xl);
  --sk-Avatar-border-radius: var(--sk-data-display-avatar-radius-2xl);
}

.sk-Avatar_main--size-4xl__Hxi5Q {
  --sk-Avatar-size: var(--sk-data-display-avatar-size-4xl);
  --sk-Avatar-border-radius: var(--sk-data-display-avatar-radius-4xl);
}

.sk-Avatar_main--size-6xl___zsga {
  --sk-Avatar-size: var(--sk-data-display-avatar-size-6xl);
  --sk-Avatar-border-radius: var(--sk-data-display-avatar-radius-6xl);
}

}
@layer ui {
  .sk-Badge_main__AVFxz {
  --sk-Badge-y-padding: var(--sk-space-4);
  --sk-Badge-x-padding: var(--sk-space-8);
  --sk-Badge-label-spacing: var(--sk-space-4);
  --sk-Badge-height: calc(var(--sk-Badge-y-padding) * 2 + var(--sk-typography-data-display-badge-text-typography-m-line-height));

  display: inline-flex;
  box-sizing: border-box;
  flex-grow: 0;
  align-items: center;
  align-self: end;
  justify-content: center;
  min-width: 0; /* Required for overflow: ellipsis; */
  height: var(--sk-Badge-height);
  padding: var(--sk-Badge-y-padding) calc(var(--sk-Badge-x-padding) + var(--sk-Badge-label-spacing));
  overflow: hidden;
  border-radius: var(--sk-radius-20);
  font-family: var(--sk-typography-data-display-badge-text-typography-m-font-family);
  font-weight: var(--sk-typography-data-display-badge-text-typography-m-font-weight);
  gap: var(--sk-Badge-label-spacing);
}

.sk-Badge_label__f1crk {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.sk-Badge_icon__M8ilK {
  flex-shrink: 0;
}

.sk-Badge_main--withIcon__uFfRO {
  padding-right: calc(var(--sk-Badge-x-padding) + var(--sk-Badge-label-spacing));
  padding-left: var(--sk-Badge-x-padding);
}

.sk-Badge_main--medium__d1Pq_ {
  font-size: var(--sk-typography-data-display-badge-text-typography-m-font-size);
  font-weight: var(--sk-typography-data-display-badge-text-typography-m-font-weight);
  line-height: var(--sk-typography-data-display-badge-text-typography-m-line-height);
}

.sk-Badge_main--large__WUCcE {
  --sk-Badge-height: calc(var(--sk-Badge-y-padding) * 2 + var(--sk-typography-data-display-badge-text-typography-l-line-height));

  font-size: var(--sk-typography-data-display-badge-text-typography-l-font-size);
  font-weight: var(--sk-typography-data-display-badge-text-typography-l-font-weight);
  line-height: var(--sk-typography-data-display-badge-text-typography-l-line-height);
}

.sk-Badge_main--tertiary__J_3Du {
  border-width: var(--sk-data-display-badge-size-border-tertiary);
  border-style: solid;
}

.sk-Badge_main--light__Zj3_v.sk-Badge_main--primary__yAKxY {
  background-color: var(--sk-data-display-badge-color-background-primary-light);
  color: var(--sk-data-display-badge-color-primary-light);
}

.sk-Badge_main--light__Zj3_v.sk-Badge_main--primary__yAKxY.sk-Badge_isFaded__Mri4s {
  background-color: var(--sk-data-display-badge-color-background-primary-faded-light);
  color: var(--sk-data-display-badge-color-primary-faded-light);
}

.sk-Badge_main--light__Zj3_v.sk-Badge_main--secondary__wgMjW {
  background-color: var(--sk-data-display-badge-color-background-secondary-light);
  color: var(--sk-data-display-badge-color-secondary-light);
}

.sk-Badge_main--light__Zj3_v.sk-Badge_main--secondary__wgMjW.sk-Badge_isFaded__Mri4s {
  background-color: var(--sk-data-display-badge-color-background-secondary-faded-light);
  color: var(--sk-data-display-badge-color-secondary-faded-light);
}

.sk-Badge_main--light__Zj3_v.sk-Badge_main--tertiary__J_3Du {
  border-color: var(--sk-data-display-badge-color-border-tertiary-light);
  background-color: var(--sk-data-display-badge-color-background-tertiary-light);
  color: var(--sk-data-display-badge-color-tertiary-light);
}

.sk-Badge_main--light__Zj3_v.sk-Badge_main--success__3Jnl2 {
  background-color: var(--sk-data-display-badge-color-background-success-light);
  color: var(--sk-data-display-badge-color-success-light);
}

.sk-Badge_main--light__Zj3_v.sk-Badge_main--success__3Jnl2.sk-Badge_isFaded__Mri4s {
  background-color: var(--sk-data-display-badge-color-background-success-faded-light);
  color: var(--sk-data-display-badge-color-success-faded-light);
}

.sk-Badge_main--light__Zj3_v.sk-Badge_main--warning__x2jJg {
  background-color: var(--sk-data-display-badge-color-background-warning-light);
  color: var(--sk-data-display-badge-color-warning-light);
}

.sk-Badge_main--light__Zj3_v.sk-Badge_main--warning__x2jJg.sk-Badge_isFaded__Mri4s {
  background-color: var(--sk-data-display-badge-color-background-warning-faded-light);
  color: var(--sk-data-display-badge-color-warning-faded-light);
}

.sk-Badge_main--light__Zj3_v.sk-Badge_main--error__X5oen {
  background-color: var(--sk-data-display-badge-color-background-error-light);
  color: var(--sk-data-display-badge-color-error-light);
}

.sk-Badge_main--light__Zj3_v.sk-Badge_main--error__X5oen.sk-Badge_isFaded__Mri4s {
  background-color: var(--sk-data-display-badge-color-background-error-faded-light);
  color: var(--sk-data-display-badge-color-error-faded-light);
}

.sk-Badge_main--dark__p0R59.sk-Badge_main--primary__yAKxY {
  background-color: var(--sk-data-display-badge-color-background-primary-dark);
  color: var(--sk-data-display-badge-color-primary-dark);
}

.sk-Badge_main--dark__p0R59.sk-Badge_main--primary__yAKxY.sk-Badge_isFaded__Mri4s {
  background-color: var(--sk-data-display-badge-color-background-primary-faded-dark);
  color: var(--sk-data-display-badge-color-primary-faded-dark);
}

.sk-Badge_main--dark__p0R59.sk-Badge_main--secondary__wgMjW {
  background-color: var(--sk-data-display-badge-color-background-secondary-dark);
  color: var(--sk-data-display-badge-color-secondary-dark);
}

.sk-Badge_main--dark__p0R59.sk-Badge_main--secondary__wgMjW.sk-Badge_isFaded__Mri4s {
  background-color: var(--sk-data-display-badge-color-background-secondary-faded-dark);
  color: var(--sk-data-display-badge-color-secondary-faded-dark);
}

.sk-Badge_main--dark__p0R59.sk-Badge_main--tertiary__J_3Du {
  border-color: var(--sk-data-display-badge-color-border-tertiary-dark);
  background-color: var(--sk-data-display-badge-color-background-tertiary-dark);
  color: var(--sk-data-display-badge-color-tertiary-dark);
}

.sk-Badge_main--dark__p0R59.sk-Badge_main--success__3Jnl2 {
  background-color: var(--sk-data-display-badge-color-background-success-dark);
  color: var(--sk-data-display-badge-color-success-dark);
}

.sk-Badge_main--dark__p0R59.sk-Badge_main--success__3Jnl2.sk-Badge_isFaded__Mri4s {
  background-color: var(--sk-data-display-badge-color-background-success-faded-dark);
  color: var(--sk-data-display-badge-color-success-faded-dark);
}

.sk-Badge_main--dark__p0R59.sk-Badge_main--warning__x2jJg {
  background-color: var(--sk-data-display-badge-color-background-warning-dark);
  color: var(--sk-data-display-badge-color-warning-dark);
}

.sk-Badge_main--dark__p0R59.sk-Badge_main--warning__x2jJg.sk-Badge_isFaded__Mri4s {
  background-color: var(--sk-data-display-badge-color-background-warning-faded-dark);
  color: var(--sk-data-display-badge-color-warning-faded-dark);
}

.sk-Badge_main--dark__p0R59.sk-Badge_main--error__X5oen {
  background-color: var(--sk-data-display-badge-color-background-error-dark);
  color: var(--sk-data-display-badge-color-error-dark);
}

.sk-Badge_main--dark__p0R59.sk-Badge_main--error__X5oen.sk-Badge_isFaded__Mri4s {
  background-color: var(--sk-data-display-badge-color-background-error-faded-dark);
  color: var(--sk-data-display-badge-color-error-faded-dark);
}

}
@layer ui {
  .sk-Signature_main__ihSMF {
  display: inline-flex;
  gap: var(--sk-space-16);
  align-items: center;
  width: -moz-fit-content;
  width: fit-content;
}

.sk-Signature_avatar__xelcE {
  flex-shrink: 0;
}

.sk-Signature_author__gQyZL > .sk-Text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.sk-Signature_main--light__McguI {
  color: var(--sk-color-black);
}

.sk-Signature_main--dark__s6y9D {
  color: var(--sk-color-white);
}

}
